import { FunctionComponent } from "react";
import NavbarF from "./NavbarF";
import React from "react";
export type FrameComponent2Type = {
  className?: string;
};

const FrameComponent2: FunctionComponent<FrameComponent2Type> = ({
  className = "",
}) => {
  return (
    <section
      className={`self-stretch flex flex-col items-start justify-start pt-14 pb-[75px] box-border  text-left text-63xl text-gray1-500 font-polysans-trial overflow-hidden ${className}`}
    >
     
      <div className="h-auto flex-1 [background:conic-gradient(from_-77.89deg_at_50%_50%,_#1a1a2c_-211.42deg,_#070614_22.04deg,_#1a1a2c_148.58deg,_#070614_382.04deg)] flex flex-col items-center justify-center pt-0 box-border gap-4 md:box-border">
       
        <div className="w-[80%]   h-full  flex flex-row items-start justify-center gap-1 mt-20  md:gap-4 md:flex-col self-center ">
            <div className="w-[50%] md:w-full flex flex-col items-start justify-start pt-10  pb-0 box-border   md:pt-4  animate__animated animate__fadeInLeft ">
              <div className="self-stretch flex flex-col items-start justify-start gap-[32px]  md:gap-[16px]">
                <h1 className="m-0 self-stretch text-white  leading-none md:text-22xl text-[55px] font-normal md:text-center  ">
                  Take control of your crypto wealth easily.
                </h1>
                <div className="w-full relative text-base md:text-sm tracking-[-0.02em]   font-poppins text-dimgray-200  md:text-center ">
                  The crypto app trusted by million of satisfied users, our
                  financial services have made a real impact on people’s lives
                </div>
                <div className="w-full flex flex-row items-start justify-start md:items-center md:justify-center gap-[9px] ">
                  <button className="w-48 md:w-40  cursor-pointer py-3 pr-5 pl-6 bg-gray1-600  rounded-[41.38px] box-border flex flex-row items-start justify-start gap-[9.8px] whitespace-nowrap border-[2.4px] border-solid border-neutral-03">
                    <img
                      className="h-6 w-6 relative"
                      alt=""
                      src="/vuesaxboldapple.svg"
                    />
                    <div className="relative text-base-8 md:text-sm tracking-[-0.01em] leading-[30px] font-polysans-trial text-font-color-white text-left inline-block ">
                      App Store
                    </div>
                  </button>
                  <button className="w-48 md:w-40  cursor-pointer py-3 pr-5 pl-6 bg-gray1-600  rounded-[41.38px] box-border flex flex-row items-start justify-start gap-[9.8px] whitespace-nowrap border-[2.4px] border-solid border-neutral-03">
                  <img
                        className="h-6 w-6 relative"
                        alt=""
                        src="/group.svg"
                      />
                    <div className="relative text-base-8 md:text-sm  tracking-[-0.01em] leading-[30px] font-polysans-trial text-font-color-white text-left inline-block ">
                    Google play
                    </div>
                  </button>
                 
                </div>
              </div>
            </div>
            <img
              className="h-[600px] md:h-[350px] w-full md:object-contain overflow-hidden object-contain  animate__animated animate__fadeInRight "
              loading="lazy"
              alt=""
              src="/peniwallet-home-screen-2@2x.png"
            />
          </div>
     
      </div>
    </section>
  );
};

export default FrameComponent2;
