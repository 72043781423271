import { FunctionComponent } from "react";
import StepsContent from "./StepsContent";
import DownloadInstructions from "./DownloadInstructions";
import { useSpring, animated } from 'react-spring';
import { useInView } from 'react-intersection-observer';
import React from "react";
export type FrameComponent6Type = {
  className?: string;
};

const FrameComponent6: FunctionComponent<FrameComponent6Type> = ({
  className = "",
}) => {
  const [refLeft, inViewLeft] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  const [refRight, inViewRight] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  
  const [refBottom, inViewBottom] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  const [refAboutLeft, inViewAboutLeft] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  const [refAboutRight, inViewAboutRight] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  const [refAboutBottom, inViewAboutBottom] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  
  const animation = useSpring({
    opacity: 1,
    transform: 'translateY(0)',
    from: { opacity: 0, transform: 'translateY(50px)' },
    config: { duration: 1000 },
  });
  const animationLeft = useSpring({
    opacity: inViewLeft ? 1 : 0,
    transform: inViewLeft ? 'translateX(0)' : 'translateX(-50px)', // Translate from left
    config: { duration: 700 },
  });
  
  const animationRight = useSpring({
    opacity: inViewRight ? 1 : 0,
    transform: inViewRight ? 'translateX(0)' : 'translateX(50px)', // Translate from right
    config: { duration: 700 },
  });
  
  const animationAbout = useSpring({
    opacity: 1,
    transform: 'translateY(0)',
    from: { opacity: 0, transform: 'translateY(50px)' },
    config: { duration: 700 },
  });
  const animationAboutLeft = useSpring({
    opacity: inViewAboutLeft ? 1 : 0,
    transform: inViewAboutLeft ? 'translateX(0)' : 'translateX(-50px)', // Translate from left
    config: { duration: 700 },
  });
  
  const animationAboutRight = useSpring({
    opacity: inViewAboutRight ? 1 : 0,
    transform: inViewAboutRight ? 'translateX(0)' : 'translateX(50px)', // Translate from right
    config: { duration: 700 },
  });
  const animationAboutBottom = useSpring({
    opacity: inViewAboutBottom ? 1 : 0,
    transform: inViewAboutBottom ? 'translateY(0)' : 'translateY(50px)',
    config: { duration: 700 }, // Adjust animation duration
  });
  const bottomAnimation = useSpring({
    opacity: inViewBottom ? 1 : 0,
    transform: inViewBottom ? 'translateY(0)' : 'translateY(50px)',
    config: { duration: 700 }, // Adjust animation duration
  });

  return (
    <section
      className={`w-full flex flex-col items-start justify-center pt-0 px-5 pb-5 box-border max-w-full text-left text-21xl text-font-color-white font-polysans-trial ${className}`}
    >
    
      <section
      className={`self-stretch flex flex-row items-start justify-center pt-0 pb-20 px-32 md:px-6  box-border w-full text-left text-29xl text-font-color-white font-polysans-trial md:box-border  sm:box-border ${className}`}
    >
      <div className="flex flex-row items-start justify-start w-full md:gap-[36px] ] xl:flex-wrap xl:gap-[73px]">
      <animated.div ref={refLeft} style={animationLeft} className="w-1/2 flex flex-col items-start justify-start pt-[60px] px-0 pb-0 box-border  md:pt-[39px] md:box-border md:min-w-full xl:flex-1">
          <div className="self-stretch flex flex-col items-start justify-start gap-[20px] max-w-full">
           
            <div className="self-stretch flex flex-col items-start justify-start gap-[20px] ">
              <h1 className="m-0 w-[455px] relative text-inherit tracking-[-0.01em]  font-normal text-22xl inline-block max-w-full md:text-19xl md:leading-[48px] sm:text-10xl sm:leading-[36px]">
              Securely Send crypto to multiple wallets with Our Spray feature
              </h1>
              
              <div className="w-[374px] flex flex-col items-start justify-start gap-[29px] max-w-full text-lg">
              <StepsContent
                iconOutlinedActionDownloa="/vector-11.svg"
                downloadAppSignUp="Select create a spray party "
                firstDownloadOurAppOnAppS="First select the spray tab to open the spray menu, then click on create a spray party "
                propWidth="346px"
               
              />
              <div className="self-stretch flex flex-col items-start justify-start gap-[3px] max-w-full">
                <div className="w-[367px] flex flex-row items-start justify-start py-0 px-[73px] box-border max-w-full sm:pl-5 sm:pr-5 sm:box-border">
                  <div className="flex-1 relative leading-[28px]">
                    Setup up your spray Party
                  </div>
                </div>
                <div className="self-stretch flex flex-row items-start justify-start gap-[29px] max-w-full text-smi text-gray-400 font-poppins sm:flex-wrap">
                  <div className="h-11 w-11 rounded-[46.48px] bg-font-color-white flex flex-row items-center justify-center py-3.5 px-2.5 box-border">
                    <img
                      className="h-6 w-6 relative"
                      loading="lazy"
                      alt=""
                      src="/vuesaxlinearsetting2.svg"
                    />
                  </div>
                  <div className="flex-1 relative tracking-[-0.03em] leading-[19px] whitespace-pre-wrap inline-block min-w-[233px] max-w-full">
                    Select the token, input a spray name, no. of participants
                    and amount per participant, then confirm. You’d be given a
                    6-digit spray code to share to others to join your spray
                    party.
                  </div>
                </div>
              </div>
              <DownloadInstructions
                vuesaxlinearlikeShapes="/vuesaxlinearmoneysend1.svg"
                startEnjoyingOurServices="Start Spray party"
                enjoyOurUnlimitedServices="Once the desired no. of participants joins you can commence your spray party. The desired no. of token will be distributed automical;ly"
                propAlignSelf="center"
                propWidth="300px"
              />
              </div>
            </div>
          </div>
        </animated.div>
       <animated.div ref={refRight} style={animationRight} className="h-[702px] md:h-[500px]   w-1/2 rounded-13xl overflow-hidden sflex flex-row  bg-royalblue-200 items-center justify-start  md:w-full  xl:flex-1">
        <img
                className="h-[702px] w-full xl:pt-20     object-cover object-top z-[2]"
                loading="lazy"
                alt=""
                src="/spray-2@2x.png"
              />
        </animated.div>
      </div>
    </section>
    <section
      className={`self-stretch flex flex-row items-start justify-center pt-0 pb-20 px-32 md:px-6  box-border w-full text-left text-29xl text-font-color-white font-polysans-trial md:box-border  sm:box-border ${className}`}
    >
      <animated.div ref={refBottom} style={bottomAnimation} className="flex flex-row items-start justify-start w-full md:gap-[36px] ] xl:flex-wrap xl:gap-[73px]">
      <div className="h-[702px] md:h-[500px]   w-1/2 rounded-13xl overflow-hidden sflex flex-row   bg-gainsboro-300  items-center justify-start  md:w-full  xl:flex-1">
        <img
                className="h-[702px] w-full xl:pt-20     object-cover object-top z-[2]"
                loading="lazy"
                alt=""
                src="/spray-1@2x.png"
              />
        </div>
        <div className="w-1/2 flex flex-col items-start justify-start pt-[60px] px-0 pb-0 box-border  md:pt-[39px] md:box-border md:min-w-full xl:flex-1">
          <div className="self-stretch flex flex-col items-start justify-start gap-[20px] max-w-full">
           
            <div className="self-stretch flex flex-col items-start justify-start gap-[20px] ">
              <h1 className="m-0 w-[455px] relative text-inherit tracking-[-0.01em]  font-normal text-22xl inline-block max-w-full md:text-19xl md:leading-[48px] sm:text-10xl sm:leading-[36px]">
              How to join an ongoing spray party
              </h1>
              
              <div className="w-[374px] flex flex-col items-start justify-start gap-[29px] max-w-full text-lg">
              <StepsContent
                iconOutlinedActionDownloa="/vuesaxlinearprofile2user.svg"
                downloadAppSignUp="Select join a spray party "
                firstDownloadOurAppOnAppS="Input your 6-digit inivitation code generated by the spray host into the field provided"
                propWidth="346px"
               
              />
              <StepsContent
                iconOutlinedActionDownloa="/vuesaxlinearsetting.svg"
                downloadAppSignUp="Participate in spray"
                firstDownloadOurAppOnAppS="You’ll get a pop up screen notifying you the you’ve joined successfully"
                propWidth="362px"
                
              />
              <StepsContent
                iconOutlinedActionDownloa="/vuesaxlinearlikeshapes.svg"
                downloadAppSignUp="Voila! You’ve joined a Spray party"
                firstDownloadOurAppOnAppS="Wait for host to start the spray and you’ll receive your Spray token."
                propWidth="unset"
                propAlignSelf="stretch"
              />
              </div>
            </div>
          </div>
        </div>
      
      </animated.div>
    </section>
    </section>
  );
};

export default FrameComponent6;
