import { FunctionComponent, useMemo, type CSSProperties } from "react";
import React from "react";
export type StepsContentType = {
  className?: string;
  iconOutlinedActionDownloa?: string;
  downloadAppSignUp?: string;
  firstDownloadOurAppOnAppS?: string;

  propAlignSelf?: "auto" | "flex-start" | "flex-end" | "center" | "baseline" | "stretch";
  propWidth?: string | number;
};

const StepsContent: FunctionComponent<StepsContentType> = ({
  className = "",
  iconOutlinedActionDownloa,
  downloadAppSignUp,
  firstDownloadOurAppOnAppS,
  propWidth,
  propAlignSelf,
}) => {
  const stepsContentStyle: CSSProperties = useMemo(() => {
    return {
      width: propWidth,
      alignSelf: propAlignSelf,
    };
  }, [propWidth, propAlignSelf]);

  return (
    <div
      className={`w-[309px] flex flex-row items-start justify-start gap-[29px] text-left text-lg text-font-color-white font-polysans-trial ${className}`}
      style={stepsContentStyle}
    >
      <div className="flex flex-col items-start justify-start pt-[12.5px] px-0 pb-0">
        <div className="w-11 h-11 rounded-[46.48px] bg-font-color-white flex flex-row items-center justify-center py-3.5 px-2.5 box-border">
          <img
            className="h-6 w-6 relative overflow-hidden shrink-0"
            loading="lazy"
            alt=""
            src={iconOutlinedActionDownloa}
          />
        </div>
      </div>
      <div className="flex-1 flex flex-col items-start justify-start gap-[3px]">
        <div className="w-[212px] relative leading-[28px] inline-block">
          {downloadAppSignUp}
        </div>
        <div className="self-stretch relative text-smi tracking-[-0.03em] leading-[19px] font-poppins text-gray-400">
          {firstDownloadOurAppOnAppS}
        </div>
      </div>
    </div>
  );
};

export default StepsContent;
