import { FunctionComponent } from "react";
import { useSpring, animated } from 'react-spring';
import { useInView } from 'react-intersection-observer';
import React from "react";
export type ContentType = {
  className?: string;
};

const Content: FunctionComponent<ContentType> = ({ className = "" }) => {
  const [refLeft, inViewLeft] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  const [refRight, inViewRight] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  
  const [refBottom, inViewBottom] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  const [refAboutLeft, inViewAboutLeft] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  const [refAboutRight, inViewAboutRight] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  const [refAboutBottom, inViewAboutBottom] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  
  const animation = useSpring({
    opacity: 1,
    transform: 'translateY(0)',
    from: { opacity: 0, transform: 'translateY(50px)' },
    config: { duration: 1000 },
  });
  const animationLeft = useSpring({
    opacity: inViewLeft ? 1 : 0,
    transform: inViewLeft ? 'translateX(0)' : 'translateX(-50px)', // Translate from left
    config: { duration: 700 },
  });
  
  const animationRight = useSpring({
    opacity: inViewRight ? 1 : 0,
    transform: inViewRight ? 'translateX(0)' : 'translateX(50px)', // Translate from right
    config: { duration: 700 },
  });
  
  const animationAbout = useSpring({
    opacity: 1,
    transform: 'translateY(0)',
    from: { opacity: 0, transform: 'translateY(50px)' },
    config: { duration: 700 },
  });
  const animationAboutLeft = useSpring({
    opacity: inViewAboutLeft ? 1 : 0,
    transform: inViewAboutLeft ? 'translateX(0)' : 'translateX(-50px)', // Translate from left
    config: { duration: 700 },
  });
  
  const animationAboutRight = useSpring({
    opacity: inViewAboutRight ? 1 : 0,
    transform: inViewAboutRight ? 'translateX(0)' : 'translateX(50px)', // Translate from right
    config: { duration: 700 },
  });
  const animationAboutBottom = useSpring({
    opacity: inViewAboutBottom ? 1 : 0,
    transform: inViewAboutBottom ? 'translateY(0)' : 'translateY(50px)',
    config: { duration: 700 }, // Adjust animation duration
  });
  const bottomAnimation = useSpring({
    opacity: inViewBottom ? 1 : 0,
    transform: inViewBottom ? 'translateY(0)' : 'translateY(50px)',
    config: { duration: 700 }, // Adjust animation duration
  });
  return (
    <section
      className={`self-stretch flex flex-row items-start   justify-center pt-0 pb-[107.9px] pr-5 pl-[21px] box-border max-w-full text-left text-mini text-font-color-white font-polysans-trial sm:pb-[70px] sm:box-border ${className}`}
    >
      <div className="w-2/3 md:w-full flex flex-col items-start justify-start gap-[89.3px]  ">
        <div className="self-stretch flex flex-col items-start justify-start gap-[70.9px] ">
          <div className="self-stretch flex flex-row items-start justify-center py-0 px-5">
            <div className="[backdrop-filter:blur(4px)] rounded-181xl  flex flex-row items-start justify-start py-2 px-[11px] gap-[6px] whitespace-nowrap border-[1px] border-solid border-darkgray-200">
              <img
                className="h-[18px] w-[18px] relative overflow-hidden shrink-0 min-h-[18px]"
                loading="lazy"
                alt=""
                src="/filoader.svg"
              />
              <div className="relative tracking-[0.2em] inline-block min-w-[99px]">
                ABOUT US
              </div>
            </div>
          </div>
          <animated.div ref={refLeft} style={animationLeft} className="m-0 self-stretch h-[72.4px] relative text-45xl leading-[120.31%] capitalize font-normal font-inherit text-center inline-block shrink-0 md:text-32xl md:leading-[62px] sm:text-19xl sm:leading-[46px]">
            Simplifying the crypto experience for everyone
          </animated.div>
        </div>
        <animated.div ref={refBottom} style={bottomAnimation} className="self-stretch flex flex-row items-start justify-center py-0 px-5 box-border max-w-full text-center text-base text-gainsboro-300 font-poppins">
          <div className="w-[471px] relative tracking-[-0.02em] leading-[160%] font-medium inline-block shrink-0 max-w-full">
            The crypto app trusted by million of satisfied users, our financial
            services have made a real impact on people’s lives
          </div>
        </animated.div>
      </div>
    </section>
  );
};

export default Content;
