import { FunctionComponent } from "react";
import BlogPostCard from "./BlogPostCard";
import { useSpring, animated } from 'react-spring';
import { useInView } from 'react-intersection-observer';
import React from "react";
export type HeroType = {
  className?: string;
};

const Hero: FunctionComponent<HeroType> = ({ className = "" }) => {
  const [refLeft, inViewLeft] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  const [refRight, inViewRight] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  
  const [refBottom, inViewBottom] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  const [refAboutLeft, inViewAboutLeft] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  const [refAboutRight, inViewAboutRight] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  const [refAboutBottom, inViewAboutBottom] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  
  const animation = useSpring({
    opacity: 1,
    transform: 'translateY(0)',
    from: { opacity: 0, transform: 'translateY(50px)' },
    config: { duration: 1000 },
  });
  const animationLeft = useSpring({
    opacity: inViewLeft ? 1 : 0,
    transform: inViewLeft ? 'translateX(0)' : 'translateX(-50px)', // Translate from left
    config: { duration: 700 },
  });
  
  const animationRight = useSpring({
    opacity: inViewRight ? 1 : 0,
    transform: inViewRight ? 'translateX(0)' : 'translateX(50px)', // Translate from right
    config: { duration: 700 },
  });
  
  const animationAbout = useSpring({
    opacity: 1,
    transform: 'translateY(0)',
    from: { opacity: 0, transform: 'translateY(50px)' },
    config: { duration: 700 },
  });
  const animationAboutLeft = useSpring({
    opacity: inViewAboutLeft ? 1 : 0,
    transform: inViewAboutLeft ? 'translateX(0)' : 'translateX(-50px)', // Translate from left
    config: { duration: 700 },
  });
  
  const animationAboutRight = useSpring({
    opacity: inViewAboutRight ? 1 : 0,
    transform: inViewAboutRight ? 'translateX(0)' : 'translateX(50px)', // Translate from right
    config: { duration: 700 },
  });
  const animationAboutBottom = useSpring({
    opacity: inViewAboutBottom ? 1 : 0,
    transform: inViewAboutBottom ? 'translateY(0)' : 'translateY(50px)',
    config: { duration: 700 }, // Adjust animation duration
  });
  const bottomAnimation = useSpring({
    opacity: inViewBottom ? 1 : 0,
    transform: inViewBottom ? 'translateY(0)' : 'translateY(50px)',
    config: { duration: 700 }, // Adjust animation duration
  });

  return (
    <section
      className={`w-full flex flex-row items-start justify-center mt-20 pt-0  px-32 md:px-6 pb-16 box-border text-left text-5xl text-font-color-white font-polysans-trial  ${className}`}
    >
      <div className="w-full flex flex-col items-start justify-start gap-[64px] sm:gap-[16px] md:gap-[32px]">
      <animated.div ref={refLeft} style={animationLeft} className="w-full flex flex-col text-center md:text-left items-center justify-center gap-[79.3px]  sm:gap-[20px] md:gap-[40px]">
            <h1 className="m-0 self-stretch h-[-4.6px] relative text-29xl leading-[120.31%] capitalize font-normal font-inherit inline-block shrink-0 sm:text-19xl sm:leading-[46px] md:text-32xl md:leading-[62px]">
              Our blog
            </h1>
            <div className="text-base md:text-sm w-2/3  relative tracking-[-0.02em] leading-[160%] font-medium inline-block md:w-full z-[1]">
                Dive into our latest product updates, user interviews, finance
                tips, and strategic insights from the Peniwallet Team.
              </div>
          </animated.div>
          <div className="flex flex-col items-start justify-start py-0 box-border gap-[32px] w-full md:gap-[16px]">
            <h2 className="m-0 self-stretch relative text-inherit leading-[32px] font-normal font-inherit sm:text-lgi sm:leading-[26px]">
              Recent blog posts
            </h2>
            <animated.div ref={refBottom} style={bottomAnimation}className="self-stretch flex flex-row items-start justify-start w-full text-sm text-gainsboro-300 font-text-sm-normal md:gap-[16px]">
              <div className="flex-1 flex flex-col items-start justify-start gap-[32px] w-full md:gap-[16px]">
                <img
                  className="[border:none] bg-[transparent] h-[368px] w-auto [outline:none] self-stretch relative rounded-6xl w-full overflow-hidden shrink-0 object-cover"
                  // rows={18}
                  // cols={61}
                  alt=""
                  src="/image@2x.png"
                />
                <div className="self-stretch flex flex-col items-start justify-start w-full">
                  <div className="self-stretch flex flex-col items-start justify-start gap-[12px] w-full">
                    <div className="self-stretch relative leading-[20px] font-semibold whitespace-nowrap">
                      01:57 am • 17 March 2023
                    </div>
                    <div className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[16px] w-full text-5xl text-font-color-white font-polysans-trial">
                      <h2 className="m-0 flex-1 relative text-inherit leading-[32px] font-normal font-inherit inline-block min-w-[552px] w-full sm:text-lgi sm:leading-[26px] md:min-w-full">
                        Crypto Wallet Guide: Which Ones To Use and How They Work
                      </h2>
                      <div className="flex flex-col items-start justify-start pt-1 px-0 pb-0">
                        <img
                          className="w-6 h-6 relative overflow-hidden shrink-0"
                          loading="lazy"
                          alt=""
                          src="/arrowupright.svg"
                        />
                      </div>
                    </div>
                    <div className="w-[954px] relative text-base leading-[24px] font-sansation text-gray1-100 [display:-webkit-inline-box] overflow-hidden text-ellipsis [-webkit-line-clamp:2] [-webkit-box-orient:vertical] w-full">
                      Discover which crypto wallet is best for you and learn how
                      they work in our comprehensive and easy to understand
                      wallet guide.
                    </div>
                  </div>
                </div>
              </div>
            </animated.div>
          </div>
        <div className=" flex flex-row items-start justify-start py-0  box-border w-full">
          <div className="flex-1 flex flex-col items-start justify-start gap-[32px] w-full md:gap-[16px]">
            <h2 className="m-0 self-stretch relative text-inherit leading-[32px] font-normal font-inherit sm:text-lgi sm:leading-[26px]">
              All blog posts
            </h2>
            <div className="self-stretch flex flex-col items-start justify-start gap-[48px] w-full text-sm text-gainsboro-300 font-poppins md:gap-[24px]">
              <div className="self-stretch flex flex-row flex-wrap items-start justify-center gap-[32px] w-full md:gap-[16px]">
                <BlogPostCard
                  image="/image-1@2x.png"
                  author="11:12 am • 11 March 2024"
                  heading="Bitcoin halving April 2024"
                  supportingText="Lorem ipsum dolor sit amet consectetur. Arcu eget hac at aliquet. Quam cras risus molestie faucibus."
                />
                <BlogPostCard
                  image="/image-2@2x.png"
                  author="09:53 am • 8 Dec 2022"
                  heading="Web3 community management and moderation"
                  supportingText="Lorem ipsum dolor sit amet consectetur. Cras maecenas leo lectus imperdiet egestas eget id in."
                  propBorderRadius="unset"
                />
                <BlogPostCard
                  image="/image-3@2x.png"
                  author="09:36 am • 6 feb 2024"
                  heading="Peniwallet: Understanding Passkeys and Recoverability"
                  supportingText="Lorem ipsum dolor sit amet consectetur. Rhoncus ultrices nam vitae imperdiet id. Ultricies in tortor nisl."
                  propBorderRadius="25px"
                />
              </div>
              <div className="self-stretch flex flex-row flex-wrap items-start justify-center gap-[32px] w-full md:gap-[16px]">
                <div className="flex-1 flex flex-col items-start justify-start gap-[32px] min-w-[288px] w-full sm:gap-[16px]">
                  <img
                    className="self-stretch h-60 relative rounded-6xl w-full overflow-hidden shrink-0 object-cover"
                    loading="lazy"
                    alt=""
                    src="/image-4@2x.png"
                  />
                  <div className="self-stretch flex flex-col items-start justify-start w-full">
                    <div className="self-stretch flex flex-col items-start justify-start gap-[12px] w-full">
                      <div className="self-stretch relative leading-[20px] font-medium whitespace-nowrap">
                        03:19 pm • 23 Feb 2024
                      </div>
                      <div className="self-stretch flex flex-row items-start justify-start gap-[11px] w-full text-xl text-font-color-white font-polysans-trial sm:flex-wrap">
                        <div className="flex-1 relative leading-[32px] inline-block min-w-[209px] w-full sm:text-base sm:leading-[26px]">
                          Top 5 Meme coins for 2024
                        </div>
                        <div className="flex flex-col items-start justify-start pt-1 px-0 pb-0">
                          <img
                            className="w-6 h-6 relative overflow-hidden shrink-0"
                            loading="lazy"
                            alt=""
                            src="/arrowupright.svg"
                          />
                        </div>
                      </div>
                      <div className="self-stretch relative text-base leading-[24px] text-gray1-100">
                        Lorem ipsum dolor sit amet consectetur. Massa a nec leo
                        arcu sed netus. Feugiat diam aliquam sapien.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex-1 flex flex-col items-start justify-start gap-[32px] min-w-[288px] w-full sm:gap-[16px]">
                  <img
                    className="self-stretch h-60 relative rounded-6xl w-full overflow-hidden shrink-0 object-cover"
                    loading="lazy"
                    alt=""
                    src="/image-5@2x.png"
                  />
                  <div className="self-stretch flex flex-col items-start justify-start w-full">
                    <div className="self-stretch flex flex-col items-start justify-start gap-[12px] w-full">
                      <div className="self-stretch relative leading-[20px] font-medium whitespace-nowrap">
                        12:44 am • 17 Feb 2024
                      </div>
                      <div className="self-stretch flex flex-row items-start justify-start gap-[16px] w-full text-5xl text-font-color-white font-polysans-trial sm:flex-wrap">
                        <h2 className="m-0 flex-1 relative text-inherit leading-[32px] font-normal font-inherit [display:-webkit-inline-box] overflow-hidden text-ellipsis [-webkit-line-clamp:2] [-webkit-box-orient:vertical] min-w-[224px] w-full sm:text-lgi sm:leading-[26px]">
                          How to Swap crypto using peniwallet: A visual guide
                        </h2>
                        <div className="flex flex-col items-start justify-start pt-1 px-0 pb-0">
                          <img
                            className="w-6 h-6 relative overflow-hidden shrink-0"
                            loading="lazy"
                            alt=""
                            src="/arrowupright.svg"
                          />
                        </div>
                      </div>
                      <div className="self-stretch relative text-base leading-[24px] text-gray1-100">
                        Lorem ipsum dolor sit amet consectetur. Bibendum neque
                        tristique pretium ultricies nulla quam quis.
                      </div>
                    </div>
                  </div>
                </div>
                <BlogPostCard
                  image="/image-6@2x.png"
                  author="01:32pm • 23 Jan 2024"
                  heading="How to spot a crypto Scam"
                  supportingText="Lorem ipsum dolor sit amet consectetur. Donec facilisi amet euismod ullamcorper risus malesuada."
                  propBorderRadius="unset"
                />
              </div>
            </div>
          </div>
        </div>
        <animated.div ref={refAboutBottom} style={animationAboutBottom} className=" flex flex-row items-start justify-start py-0 px-8 box-border w-full text-sm text-gray1-100 font-poppins">
          <div className="flex-1 box-border flex flex-row items-start justify-between pt-5 px-0 pb-0 w-full gap-[20px] border-t-[1px] border-solid border-whitesmoke-300 md:flex-wrap">
            <div className="flex flex-col items-start justify-start pt-2.5 px-0 pb-0">
              <div className="flex flex-row items-start justify-start gap-[8px]">
                <img
                  className="h-5 w-5 relative overflow-hidden shrink-0 min-h-[20px]"
                  loading="lazy"
                  alt=""
                  src="/arrowleft.svg"
                />
                <div className="relative leading-[20px] font-medium inline-block min-w-[60px]">
                  Previous
                </div>
              </div>
            </div>
            <div className="w-[292px] flex flex-row items-start justify-start gap-[2px] text-center font-text-sm-normal">
              <div className="flex-1 rounded-lg bg-ghostwhite overflow-hidden flex flex-row items-start justify-start text-mediumslateblue">
                <div className="flex-1 rounded-31xl flex flex-row items-start justify-start py-2.5 px-4">
                  <div className="relative leading-[20px] font-medium inline-block min-w-[7px]">
                    1
                  </div>
                </div>
              </div>
              <div className="flex-1 rounded-lg overflow-hidden flex flex-row items-start justify-start">
                <div className="flex-1 rounded-lg flex flex-row items-start justify-start py-2.5 px-[15px]">
                  <div className="relative leading-[20px] font-medium inline-block min-w-[9px]">
                    2
                  </div>
                </div>
              </div>
              <div className="rounded-lg overflow-hidden flex flex-row items-start justify-start">
                <div className="rounded-lg flex flex-row items-start justify-start py-2.5 px-[15px]">
                  <div className="relative leading-[20px] font-medium inline-block min-w-[10px]">
                    3
                  </div>
                </div>
              </div>
              <div className="rounded-lg overflow-hidden flex flex-row items-start justify-start">
                <div className="rounded-lg flex flex-row items-start justify-start py-2.5 px-3.5">
                  <div className="relative leading-[20px] font-medium inline-block min-w-[12px]">
                    ...
                  </div>
                </div>
              </div>
              <div className="flex-1 rounded-lg overflow-hidden flex flex-row items-start justify-start">
                <div className="flex-1 rounded-lg flex flex-row items-start justify-start py-2.5 px-[15px]">
                  <div className="relative leading-[20px] font-medium inline-block min-w-[9px]">
                    8
                  </div>
                </div>
              </div>
              <div className="flex-1 rounded-lg overflow-hidden flex flex-row items-start justify-start">
                <div className="flex-1 rounded-lg flex flex-row items-start justify-start py-2.5 px-[15px]">
                  <div className="relative leading-[20px] font-medium inline-block min-w-[9px]">
                    9
                  </div>
                </div>
              </div>
              <div className="rounded-lg overflow-hidden flex flex-row items-start justify-start">
                <div className="rounded-lg flex flex-row items-start justify-start py-2.5 px-3">
                  <div className="relative leading-[20px] font-medium inline-block min-w-[16px]">
                    10
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-start justify-start pt-2.5 px-0 pb-0">
              <div className="flex flex-row items-start justify-start gap-[8px]">
                <div className="relative leading-[20px] font-medium inline-block min-w-[31px]">
                  Next
                </div>
                <img
                  className="h-5 w-5 relative overflow-hidden shrink-0 min-h-[20px]"
                  alt=""
                  src="/arrowright.svg"
                />
              </div>
            </div>
          </div>
        </animated.div>
      </div>
    </section>
  );
};

export default Hero;
