import { FunctionComponent, useMemo, type CSSProperties } from "react";
import { useSpring, animated } from 'react-spring';
import { useInView } from 'react-intersection-observer';
import React from "react";
export type Section5FooterType = {
  className?: string;
  peniwalletLogomark2?: string;

  /** Style props */
  
};

const Footer: FunctionComponent<Section5FooterType> = ({
  className = "",

}) => {
  const [refLeft, inViewLeft] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  const [refRight, inViewRight] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  
  const [refBottom, inViewBottom] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  const [refAboutLeft, inViewAboutLeft] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  const [refAboutRight, inViewAboutRight] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  const [refAboutBottom, inViewAboutBottom] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  
  const animation = useSpring({
    opacity: 1,
    transform: 'translateY(0)',
    from: { opacity: 0, transform: 'translateY(50px)' },
    config: { duration: 1000 },
  });
  const animationLeft = useSpring({
    opacity: inViewLeft ? 1 : 0,
    transform: inViewLeft ? 'translateX(0)' : 'translateX(-50px)', // Translate from left
    config: { duration: 700 },
  });
  
  const animationRight = useSpring({
    opacity: inViewRight ? 1 : 0,
    transform: inViewRight ? 'translateX(0)' : 'translateX(50px)', // Translate from right
    config: { duration: 700 },
  });
  
  const animationAbout = useSpring({
    opacity: 1,
    transform: 'translateY(0)',
    from: { opacity: 0, transform: 'translateY(50px)' },
    config: { duration: 700 },
  });
  const animationAboutLeft = useSpring({
    opacity: inViewAboutLeft ? 1 : 0,
    transform: inViewAboutLeft ? 'translateX(0)' : 'translateX(-50px)', // Translate from left
    config: { duration: 700 },
  });
  
  const animationAboutRight = useSpring({
    opacity: inViewAboutRight ? 1 : 0,
    transform: inViewAboutRight ? 'translateX(0)' : 'translateX(50px)', // Translate from right
    config: { duration: 700 },
  });
  const animationAboutBottom = useSpring({
    opacity: inViewAboutBottom ? 1 : 0,
    transform: inViewAboutBottom ? 'translateY(0)' : 'translateY(50px)',
    config: { duration: 700 }, // Adjust animation duration
  });
  const bottomAnimation = useSpring({
    opacity: inViewBottom ? 1 : 0,
    transform: inViewBottom ? 'translateY(0)' : 'translateY(50px)',
    config: { duration: 700 }, // Adjust animation duration
  });

  return (
    <section
      className={`self-stretch flex flex-row items-start justify-center  text-left text-45xl text-font-color-white font-polysans-trial ${className}`}
      
    >
      <div className="w-full  flex flex-col items-start justify-start py-0 box-border gap-[50px]  md:gap-[25px] md:pl-4 md:pr-4 xl:pl-32 xl:pr-32 xl:box-border">
      <div className="flex  flex-row md:flex-col  items-start w-full ">
      <animated.div ref={refLeft} style={animationLeft}  className="w-1/2 px-10 md:px-0  md:w-full flex flex-col items-start justify-start gap-[22px]  ">
                <h1 className="m-0 self-stretch relative text-27xl font-normal font-inherit sm:text-19xl sm:leading-[45px] md:text-32xl md:leading-[60px]">
                  Keep up with the latest
                </h1>
                <div className="text-base md:text-sm  leading-[19px] text-font-color-dark-500  text-left font-normal ">
                  Join our newsletter to stay up to date on features and
                  releases.
                </div>
              </animated.div>
              <animated.div ref={refRight} style={animationRight}  className="w-1/2 md:w-full px-10 md:px-0 flex  flex-col items-start justify-start gap-[24px] text-base md:text-sm">
                  <div className="relative tracking-[-0.03em] leading-[19px] inline-block">
                    Stay up to date
                  </div>
                  <div className="self-stretch flex flex-row items-start justify-start gap-[18px] md:flex-wrap">
                    <div className="flex-1 flex flex-row items-start justify-start min-w-[195px]">
                      <div className="flex-1 rounded-xl bg-gray1-1300 overflow-hidden flex flex-row items-start justify-start py-[17px] px-[23px] whitespace-nowrap">
                        <div className="w-[115px] relative tracking-[-0.03em] leading-[19px] inline-block">
                          Enter your email
                        </div>
                      </div>
                    </div>
                    <button className="cursor-pointer [border:none] md:w-full py-[14.5px] px-7 bg-royalblue-200 rounded-xl flex flex-row items-start md:justify-center justify-start hover:bg-dodgerblue">
                      <div className="relative text-smi tracking-[-0.01em] leading-[24px] font-polysans-trial text-font-color-white text-left inline-block min-w-[62px]">
                        Subscribe
                      </div>
                    </button>
                  </div>
                  <div className="w-[246px] relative text-xs tracking-[-0.03em] leading-[116.87%] inline-block text-gray-400">
                    <span>{`By subscribing you agree to our `}</span>
                    <span className="[text-decoration:underline] text-font-color-white">
                      Privacy Policy
                    </span>
                  </div>
                </animated.div>
            </div>
        <footer className="self-stretch rounded-xl bg-[#1a1a2c] flex flex-col items-start justify-start pt-[100px] px-32 md:px-4 box-border w-full text-center text-45xl text-gainsboro-300 font-poppins gap-20">
        <animated.div ref={refBottom} style={bottomAnimation} className="flex-1 flex flex-col items-center justify-start gap-[30px] w-full shrink-0">
                <p className="m-0 text-22xl  md:text-19xl">
                  Download Peniwallet Now.
                </p>
                <div className="w-full flex flex-col items-center justify-start gap-[5px]  text-xl text-silver-300">
                  <div className="self-stretch md:text-sm text-base leading-[26px] font-medium inline-block shrink-0 ">
                    <p className="m-0">{`Experience the power of PeniWallet and unlock a world of convenience, `}</p>
                    <p className="m-0">
                      simplicity, and security for your digital assets.
                    </p>
                  </div>
                  <div className="w-[409.3px] md:w-full  flex flex-row flex-wrap md:flex-col items-start justify-center gap-[10px]  mt-20">
                    <button className="cursor-pointer md:w-full py-3 px-[22px] bg-font-color-white flex-1 rounded-[41.38px] box-border flex flex-row items-center justify-center gap-[9.7px] min-w-[130px] whitespace-nowrap border-[2.4px] border-solid border-neutral-03 hover:bg-gainsboro-100 hover:box-border hover:border-[2.4px] hover:border-solid hover:border-lightgray">
                      <img
                        className="h-[29.2px] w-[29.2px] relative"
                        alt=""
                        src="/vuesaxboldapple-1.svg"
                      />
                      <div className="w-28 relative text-base-8 tracking-[-0.01em] leading-[30px] font-polysans-trial text-neutral-07 text-left inline-block">
                        Download Now
                      </div>
                    </button>
                    <button className="cursor-pointer md:w-full py-3 px-[22px] bg-font-color-white flex-1 rounded-[41.38px] box-border flex flex-row items-center justify-center gap-[9.7px] min-w-[130px] border-[2.4px] border-solid border-neutral-03">
                      <div className="w-[29.2px] overflow-hidden shrink-0 flex flex-row items-center justify-center pt-[2.5px] px-[3px] pb-[2.6px] box-border">
                        <img
                          className="h-[24.1px] w-[21.9px] relative"
                          alt=""
                          src="/group.svg"
                        />
                      </div>
                      <div className="w-28 relative text-base-8 tracking-[-0.01em] leading-[30px] font-polysans-trial text-neutral-07 text-left inline-block">
                        Download Now
                      </div>
                    </button>
                  </div>
                </div>
           </animated.div>
          <img
            className="self-stretch relative max-w-full overflow-hidden max-h-full"
            loading="lazy"
            alt=""
            src="/frame-48095854.svg"
          />
          <div className="self-stretch flex flex-row items-start justify-start gap-[32px] max-w-full text-left text-base text-darkgray-300 md:gap-[16px]">
            <div className="h-[171px] flex-1 hidden max-w-full" />
            <div className="flex-1 flex flex-row items-start justify-between py-0 pr-[13px] pl-0 box-border max-w-full gap-[20px] z-[1] lg:flex-wrap">
              <div className="flex flex-row items-start justify-start pt-0 px-0 pb-[79.2px] box-border min-w-[248px] lg:flex-1">
                <div className="flex flex-row items-start justify-start">
                  <div className="flex flex-col items-start justify-start gap-[19.8px]">
                    <div className="w-[167px] flex flex-col items-start justify-start">
                      <img
                        className="self-stretch h-[29px] relative max-w-full overflow-hidden shrink-0 object-cover"
                        loading="lazy"
                        alt=""
                        src="/peniwallet-logomark-1@2x.png"
                        />
                    </div>
                    <div className="relative text-sm">
                      The Ultimate crypto wallet app
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-row items-start justify-between w-full text-sm font-polysans-trial  lg:w-full  md:flex-wrap  md:text-sm">
                <div className=" md:w-1/2 flex  flex-col items-start justify-start pt-0 px-0 pb-7 gap-[18px]">
                  <div className="relative text-base text-font-color-white inline-block ">
                    Features
                  </div>
                  <div className="relative inline-block text-sm">
                    Buy Crypto
                  </div>
                  <div className="w-11 relative inline-block text-sm">Swaps</div>
                  <div className="relative inline-block text-sm">
                    Spray
                  </div>
                </div>
                <div className="md:w-1/2  flex flex-col items-start justify-start pt-0 px-0 pb-7 box-border gap-[18px]">
                  <div className="self-stretch relative text-base text-font-color-white">
                    Resources
                  </div>
                  <a className=" relative text-[inherit] inline-block text-sm">
                    Blog
                  </a>
                  <a className="[relative text-[inherit] inline-block text-sm">
                    FAQ
                  </a>
                  <div className="relative inline-block text-sm">
                    Support
                  </div>
                </div>
                <div className="md:w-1/2  flex flex-col items-start justify-start gap-[18px] text-base text-font-color-white">
                  <a className="[text-decoration:none] relative text-[inherit] inline-block min-w-[71px]">
                    Company
                  </a>
                  <a className="relative  text-darkgray-300 inline-block text-sm">
                    About Us
                  </a>
                </div>
                <div className="md:w-1/2  flex flex-col items-start justify-start pt-0 px-0 pb-7 box-border gap-[18px]">
                  <div className="relative text-base text-font-color-white inline-block min-w-[41px]">
                    Legal
                  </div>
                  <a className=" relative text-[inherit] inline-block text-sm">
                    Privacy Policy
                  </a>
                  <div className="self-stretch relative text-sm">Terms of Services</div>
                  <a className="relative text-[inherit] inline-blocktext-sm">
                    Cookies
                  </a>
                </div>
              </div>
            </div>
          </div>
        </footer>
        <div className="self-stretch flex flex-row items-start justify-between pt-0 px-20 pb-10 gap-[20px] text-center text-mini text-gainsboro-300 md:flex-wrap md:pl-10 md:pr-10 md:box-border">
          <div className="w-[257px] relative tracking-[-0.03em] leading-[82%] inline-block">
            © 2024 Peniwallet. All Right Reserved
          </div>
          <div className="w-[155px] md:w-full flex flex-row items-center justify-between">
            <div className="h-[31px] w-[31px] rounded-[46.48px] bg-gray1-600 flex flex-row items-center justify-center p-1.5 box-border">
              <img
                className="h-[19px] w-[19px] relative"
                loading="lazy"
                alt=""
                src="/vuesaxboldinstagram.svg"
              />
            </div>
            <div className="h-[31px] w-[31px] rounded-[46.48px] bg-gray1-600 flex flex-row items-center justify-center p-1.5 box-border">
              <img
                className="h-[19px] w-[19px] relative overflow-hidden shrink-0"
                loading="lazy"
                alt=""
                src="/icon--outlined--brand-and-logo--facebook.svg"
              />
            </div>
            <div className="h-[31px] w-[31px] rounded-[46.48px] bg-gray1-600 flex flex-row items-center justify-center p-1.5 box-border">
              <img
                className="h-[19px] w-[19px] relative overflow-hidden shrink-0"
                loading="lazy"
                alt=""
                src="/icon--outlined--brand-and-logo--twitter.svg"
              />
            </div>
            <div className="h-[31px] w-[31px] rounded-[46.48px] bg-gray1-600 flex flex-row items-center justify-center p-1.5 box-border">
              <img
                className="h-[19px] w-[19px] relative overflow-hidden shrink-0"
                loading="lazy"
                alt=""
                src="/icon--outlined--brand-and-logo--linkedin.svg"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
