import { FunctionComponent } from "react";
import { useSpring, animated } from 'react-spring';
import { useInView } from 'react-intersection-observer';
import React from "react";
export type FrameComponent5Type = {
  className?: string;
};

const FrameComponent5: FunctionComponent<FrameComponent5Type> = ({
  className = "",
}) => {
  const [refLeft, inViewLeft] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  const [refRight, inViewRight] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  
  const [refBottom, inViewBottom] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  const [refAboutLeft, inViewAboutLeft] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  const [refAboutRight, inViewAboutRight] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  const [refAboutBottom, inViewAboutBottom] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  
  const animation = useSpring({
    opacity: 1,
    transform: 'translateY(0)',
    from: { opacity: 0, transform: 'translateY(50px)' },
    config: { duration: 1000 },
  });
  const animationLeft = useSpring({
    opacity: inViewLeft ? 1 : 0,
    transform: inViewLeft ? 'translateX(0)' : 'translateX(-50px)', // Translate from left
    config: { duration: 700 },
  });
  
  const animationRight = useSpring({
    opacity: inViewRight ? 1 : 0,
    transform: inViewRight ? 'translateX(0)' : 'translateX(50px)', // Translate from right
    config: { duration: 700 },
  });
  
  const animationAbout = useSpring({
    opacity: 1,
    transform: 'translateY(0)',
    from: { opacity: 0, transform: 'translateY(50px)' },
    config: { duration: 700 },
  });
  const animationAboutLeft = useSpring({
    opacity: inViewAboutLeft ? 1 : 0,
    transform: inViewAboutLeft ? 'translateX(0)' : 'translateX(-50px)', // Translate from left
    config: { duration: 700 },
  });
  
  const animationAboutRight = useSpring({
    opacity: inViewAboutRight ? 1 : 0,
    transform: inViewAboutRight ? 'translateX(0)' : 'translateX(50px)', // Translate from right
    config: { duration: 700 },
  });
  const animationAboutBottom = useSpring({
    opacity: inViewAboutBottom ? 1 : 0,
    transform: inViewAboutBottom ? 'translateY(0)' : 'translateY(50px)',
    config: { duration: 700 }, // Adjust animation duration
  });
  const bottomAnimation = useSpring({
    opacity: inViewBottom ? 1 : 0,
    transform: inViewBottom ? 'translateY(0)' : 'translateY(50px)',
    config: { duration: 700 }, // Adjust animation duration
  });
  return (
    <section
      className={`self-stretch flex flex-row items-start justify-center pt-0 pb-[11px] pr-5 pl-[21px] box-border max-w-full text-center text-45xl text-gainsboro-300 font-polysans-trial ${className}`}
    >
      <div className="w-[607px] flex flex-col items-start justify-start gap-[28px] max-w-full">
        <div className="self-stretch flex flex-row items-start justify-start py-0 px-[11px] box-border max-w-full">
          <div className="flex-1 flex flex-col items-start justify-start gap-[18px] max-w-full">
            <div className="self-stretch flex flex-row items-start justify-center py-0 pr-[21px] pl-5">
              <button className="cursor-pointer py-2 px-[11px] bg-darkslategray-500 [backdrop-filter:blur(4px)] rounded-181xl flex flex-row items-start justify-start gap-[8px] whitespace-nowrap border-[1px] border-solid border-darkgray-200 hover:bg-dimgray-300 hover:box-border hover:border-[1px] hover:border-solid hover:border-gray1-1000">
                <img
                  className="h-[18px] w-[18px] relative overflow-hidden shrink-0 min-h-[18px]"
                  alt=""
                  src="/filoader.svg"
                />
                <div className="relative text-mini font-polysans-trial text-font-color-white text-left inline-block min-w-[92px]">
                  How it works
                </div>
              </button>
            </div>
            <animated.div ref={refAboutLeft} style={animationAboutLeft} className="m-0 self-stretch relative text-inherit tracking-[-0.03em] leading-[116.87%] font-normal font-inherit sm:text-19xl sm:leading-[45px] md:text-32xl md:leading-[60px]">
              How our spray feature works
            </animated.div>
          </div>
        </div>
        <div className="self-stretch h-[81px] relative text-lg tracking-[-0.04em] leading-[149%] font-medium font-poppins text-lightslategray inline-block">
        <animated.div ref={refBottom} style={bottomAnimation}className="m-0">
            Enjoy a smooth mobile app and desktop experience with easy-to-use,
            powerful tools to support your entire Web3 journey.
          </animated.div>
        </div>
      </div>
    </section>
  );
};

export default FrameComponent5;
