import { FunctionComponent } from "react";
import { useSpring, animated } from 'react-spring';
import { useInView } from 'react-intersection-observer';
import React from "react";
export type FrameComponent7Type = {
  className?: string;
};

const FrameComponent7: FunctionComponent<FrameComponent7Type> = ({
  className = "",
}) => {
  const [refLeft, inViewLeft] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  const [refRight, inViewRight] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  
  const [refBottom, inViewBottom] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  const [refAboutLeft, inViewAboutLeft] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  const [refAboutRight, inViewAboutRight] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  const [refAboutBottom, inViewAboutBottom] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  
  const animation = useSpring({
    opacity: 1,
    transform: 'translateY(0)',
    from: { opacity: 0, transform: 'translateY(50px)' },
    config: { duration: 1000 },
  });
  const animationLeft = useSpring({
    opacity: inViewLeft ? 1 : 0,
    transform: inViewLeft ? 'translateX(0)' : 'translateX(-50px)', // Translate from left
    config: { duration: 700 },
  });
  
  const animationRight = useSpring({
    opacity: inViewRight ? 1 : 0,
    transform: inViewRight ? 'translateX(0)' : 'translateX(50px)', // Translate from right
    config: { duration: 700 },
  });
  
  const animationAbout = useSpring({
    opacity: 1,
    transform: 'translateY(0)',
    from: { opacity: 0, transform: 'translateY(50px)' },
    config: { duration: 700 },
  });
  const animationAboutLeft = useSpring({
    opacity: inViewAboutLeft ? 1 : 0,
    transform: inViewAboutLeft ? 'translateX(0)' : 'translateX(-50px)', // Translate from left
    config: { duration: 700 },
  });
  
  const animationAboutRight = useSpring({
    opacity: inViewAboutRight ? 1 : 0,
    transform: inViewAboutRight ? 'translateX(0)' : 'translateX(50px)', // Translate from right
    config: { duration: 700 },
  });
  const animationAboutBottom = useSpring({
    opacity: inViewAboutBottom ? 1 : 0,
    transform: inViewAboutBottom ? 'translateY(0)' : 'translateY(50px)',
    config: { duration: 700 }, // Adjust animation duration
  });
  const bottomAnimation = useSpring({
    opacity: inViewBottom ? 1 : 0,
    transform: inViewBottom ? 'translateY(0)' : 'translateY(50px)',
    config: { duration: 700 }, // Adjust animation duration
  });
  return (
    <section
      className={`self-stretch flex flex-row items-start justify-start py-40 px-32 md:px-6 pb-[43px] box-border max-w-full text-left text-lg bg-gray1-400 text-font-color-white font-polysans-trial  md:box-border ${className}`}
    >
     <div className="w-full flex flex-row items-start justify-between gap-[20px] z-[1] lg:flex-wrap">
     <animated.div ref={refAboutLeft} style={animationAboutLeft} className="m-0 h-[153px] w-1/3 md:w-full relative text-13xl tracking-[-0.01em] leading-[51px] font-normal font-inherit inline-block shrink-0 sm:text-5xl sm:leading-[31px] md:text-13xl md:leading-[41px]">
            Safety and security guaranteed
          </animated.div>
          <animated.div ref={refAboutBottom} style={animationAboutBottom} className="w-1/3 md:w-full flex flex-row items-start justify-start gap-[20px] max-w-full sm:flex-wrap">
            <div className="h-12 w-12 shadow-[0px_4px_12px_rgba(47,_43,_67,_0.12),_0px_-1px_0px_rgba(47,_43,_67,_0.1)_inset] rounded-21xl bg-font-color-white box-border flex flex-row items-center justify-center py-4 px-[15px] border-[1px] border-solid border-gray1-300">
              <img
                className="h-6 w-6 relative"
                loading="lazy"
                alt=""
                src="/outlinestatuslock.svg"
              />
            </div>
            <div className="flex-1 flex flex-col items-start justify-start gap-2 min-w-[221px] max-w-full">
              <div className="w-[157px] relative text-base inline-block">
                Top Notch Security
              </div>
              <div className="self-stretch relative text-sm leading-[20px] font-poppins text-gray1-1100">
                With PeniWallet, security is our top priority. Our app employs
                state-of-the-art encryption protocols and follows industry best
                practices to safeguard your digital assets.
              </div>
            </div>
          </animated.div>
          <animated.div ref={refRight} style={animationRight} className="w-1/3 md:w-full flex flex-row items-start justify-start py-0 px-0 box-border gap-[20px] max-w-full sm:flex-wrap">
            <div className="h-12 w-12 shadow-[0px_4px_12px_rgba(47,_43,_67,_0.12),_0px_-1px_0px_rgba(47,_43,_67,_0.1)_inset] rounded-21xl bg-font-color-white box-border flex flex-row items-center justify-center py-4 px-[15px] border-[1px] border-solid border-silver-200">
              <img
                className="h-6 w-6 relative"
                loading="lazy"
                alt=""
                src="/vuesaxlinearshieldsecurity.svg"
              />
            </div>
            <div className="flex-1 flex flex-col items-start justify-start gap-2  w-full">
              <div className="w-[127px] relative  text-base">
                Data protection
              </div>
              <div className="self-stretch relative  leading-[20px] font-poppins text-gray1-1100 text-sm">{`Your information and that of your customers are 100% safe on Thepeer. We operate a strict AML & CFT policy.`}</div>
            </div>
          </animated.div>
        </div>
    </section>
  );
};

export default FrameComponent7;
