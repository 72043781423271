import { FunctionComponent } from "react";
import React from "react";
export type InputFieldType = {
  className?: string;
  label?: string;
  contentPlaceholder?: string;
};

const InputField: FunctionComponent<InputFieldType> = ({
  className = "",
  label,
  contentPlaceholder,
}) => {
  return (
    <div
      className={`self-stretch flex flex-col items-start justify-start max-w-full text-left text-sm text-gainsboro-200 font-polysans-trial ${className}`}
    >
      <div className="self-stretch flex flex-col items-start justify-start gap-[6px] max-w-full">
        <div className="self-stretch flex flex-col items-start justify-start gap-[6px] max-w-full">
          <div className="relative leading-[20px] inline-block min-w-[37px]">
            {label}
          </div>
          <div className="self-stretch shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] rounded-lg box-border overflow-hidden flex flex-row items-center justify-start py-2.5 px-[15px] gap-[8px] max-w-full border-[1px] border-solid border-dimgray-100">
            <input
              className="w-full [border:none] [outline:none] bg-[transparent] h-6 flex-1 flex flex-row items-center justify-start font-poppins text-base text-gray1-100 min-w-[250px] max-w-full"
              placeholder={contentPlaceholder}
              type="text"
            />
            <img
              className="h-4 w-4 relative hidden"
              alt=""
              src="/help-icon.svg"
            />
          </div>
        </div>
        <div className="w-80 relative leading-[20px] font-text-sm-normal text-gray-500 hidden">
          This is a hint text to help user.
        </div>
      </div>
    </div>
  );
};

export default InputField;
