import { FunctionComponent } from "react";
import FrameComponent1 from "./FrameComponent1";
import React from "react";
export type PoliciesConnectType = {
  className?: string;
};

const PoliciesConnect: FunctionComponent<PoliciesConnectType> = ({
  className = "",
}) => {
  return (
    <section
      className={`w-[1716px] flex flex-row items-start justify-center py-0 px-5 box-border max-w-full ${className}`}
    >
      <footer className="w-[1440px] flex flex-row items-start justify-start max-w-full text-left text-45xl text-font-color-white font-polysans-trial">
        <div className="flex-1 flex flex-col items-start justify-start py-0 px-20 box-border gap-[50px] max-w-full lg:pl-10 lg:pr-10 lg:box-border sm:gap-[25px]">
          <div className="w-[1120px] hidden flex-col items-start justify-between min-h-[283px] max-w-full">
            <div className="self-stretch flex flex-row items-center justify-between max-w-full gap-[20px] md:flex-wrap">
              <div className="w-[482px] flex flex-col items-start justify-start gap-[22px] min-w-[482px] max-w-full sm:min-w-full md:flex-1">
                <div className="self-stretch h-[150px] relative tracking-[-0.03em] leading-[116.87%] inline-block sm:text-19xl sm:leading-[45px] md:text-32xl md:leading-[60px]">
                  Keep up with the latest
                </div>
                <div className="h-[19px] relative text-base tracking-[-0.03em] leading-[116.87%] text-font-color-dark-500 text-center inline-block max-w-full">
                  Join our newsletter to stay up to date on features and
                  releases.
                </div>
              </div>
              <div className="flex flex-col items-start justify-start py-5 px-0 box-border gap-[24px] min-w-[436px] max-w-full text-base sm:min-w-full md:flex-1">
                <div className="h-[19px] relative tracking-[-0.03em] leading-[116.87%] inline-block">
                  Stay up to date
                </div>
                <div className="w-[436px] flex flex-row items-end justify-start gap-[18px] max-w-full sm:flex-wrap">
                  <div className="h-[53px] flex-1 relative min-w-[195px]">
                    <div className="absolute top-[0px] left-[0px] rounded-13xl bg-gray1-1300 w-full h-full overflow-hidden flex flex-row items-center justify-start py-2 px-[23px] box-border whitespace-nowrap">
                      <div className="relative tracking-[-0.03em] leading-[116.87%]">
                        Enter your email
                      </div>
                    </div>
                  </div>
                  <div className="h-[53px] w-[118px] rounded-13xl bg-royalblue-200 flex flex-row items-center justify-center py-3 px-5 box-border text-smi">
                    <div className="relative tracking-[-0.01em] leading-[24px]">
                      Subscribe
                    </div>
                  </div>
                </div>
                <div className="h-3.5 relative text-xs tracking-[-0.03em] leading-[116.87%] inline-block text-gray-400">
                  <span>{`By subscribing you agree to our `}</span>
                  <span className="[text-decoration:underline] text-font-color-white">
                    Privacy Policy
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="self-stretch rounded-13xl bg-gray1-400 flex flex-col items-start justify-start pt-[100px] px-20 pb-[85.7px] box-border gap-[79px] max-w-full text-center text-gainsboro-300 font-poppins lg:pl-10 lg:pr-10 lg:box-border sm:gap-[20px] sm:gap-[39px] sm:pt-[65px] sm:pb-14 sm:box-border">
            <div className="self-stretch flex flex-row items-start justify-start max-w-full">
              <div className="flex-1 flex flex-row items-start justify-start max-w-full">
                <div className="flex-1 flex flex-col items-center justify-start gap-[30px] max-w-full">
                  <h1 className="m-0 self-stretch h-[67.1px] relative text-inherit tracking-[-0.03em] leading-[82%] font-medium font-inherit inline-block shrink-0 sm:text-19xl sm:leading-[31px] md:text-32xl md:leading-[42px]">
                    Download Peniwallet Now.
                  </h1>
                  <div className="w-[738px] flex flex-col items-center justify-start gap-[5px] max-w-full text-xl text-silver-300">
                    <div className="self-stretch h-[79px] relative leading-[26px] font-medium inline-block shrink-0 sm:text-base sm:leading-[21px]">
                      <p className="m-0">{`Experience the power of PeniWallet and unlock a world of convenience, `}</p>
                      <p className="m-0">
                        simplicity, and security for your digital assets.
                      </p>
                    </div>
                    <div className="w-[409.3px] flex flex-row flex-wrap items-start justify-center gap-[10px] max-w-full">
                      <button className="cursor-pointer py-3 px-[22px] bg-font-color-white flex-1 rounded-[41.38px] box-border flex flex-row items-center justify-center gap-[9.7px] min-w-[130px] whitespace-nowrap border-[2.4px] border-solid border-neutral-03 hover:bg-gainsboro-100 hover:box-border hover:border-[2.4px] hover:border-solid hover:border-lightgray">
                        <img
                          className="h-[29.2px] w-[29.2px] relative"
                          alt=""
                          src="/vuesaxboldapple-1.svg"
                        />
                        <div className="w-28 relative text-base-8 tracking-[-0.01em] leading-[30px] font-polysans-trial text-neutral-07 text-left inline-block">
                          Download Now
                        </div>
                      </button>
                      <button className="cursor-pointer py-3 px-[22px] bg-font-color-white flex-1 rounded-[41.38px] box-border flex flex-row items-center justify-center gap-[9.7px] min-w-[130px] border-[2.4px] border-solid border-neutral-03">
                        <div className="w-[29.2px] overflow-hidden shrink-0 flex flex-row items-center justify-center pt-[2.5px] px-[3px] pb-[2.6px] box-border">
                          <img
                            className="h-[24.1px] w-[21.9px] relative"
                            alt=""
                            src="/group.svg"
                          />
                        </div>
                        <div className="w-28 relative text-base-8 tracking-[-0.01em] leading-[30px] font-polysans-trial text-neutral-07 text-left inline-block">
                          Download Now
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <img
              className="self-stretch relative max-w-full overflow-hidden max-h-full"
              loading="lazy"
              alt=""
              src="/frame-48095854.svg"
            />
            <div className="self-stretch flex flex-row items-start justify-start gap-[32px] max-w-full text-left text-base text-darkgray-300 sm:gap-[16px]">
              <div className="h-[171px] flex-1 hidden max-w-full" />
              <div className="flex-1 flex flex-row items-start justify-between py-0 pr-[13px] pl-0 box-border max-w-full gap-[20px] z-[1] lg:flex-wrap">
                <div className="flex flex-row items-start justify-start pt-0 px-0 pb-[79.2px] box-border min-w-[248px] lg:flex-1">
                  <div className="flex flex-row items-start justify-start">
                    <div className="flex flex-col items-start justify-start gap-[19.8px]">
                      <div className="w-[167px] flex flex-col items-start justify-start shrink-0">
                        <img
                          className="self-stretch h-[29px] relative max-w-full overflow-hidden shrink-0 object-cover"
                          loading="lazy"
                          alt=""
                          src="/peniwallet-logomark-2@2x.png"
                        />
                      </div>
                      <div className="relative shrink-0">
                        The Ultimate crypto wallet app
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-row items-start justify-start gap-[87px] min-w-[603px] max-w-full text-sm font-polysans-trial lg:flex-1 sm:gap-[22px] sm:gap-[43px] md:flex-wrap md:min-w-full">
                  <div className="flex flex-col items-start justify-start pt-0 px-0 pb-7 gap-[18px]">
                    <a className="[text-decoration:none] relative text-base text-font-color-white inline-block min-w-[66px]">
                      Features
                    </a>
                    <a className="[text-decoration:none] relative text-[inherit] inline-block min-w-[75px]">
                      Buy Crypto
                    </a>
                    <a className="[text-decoration:none] w-11 relative text-[inherit] inline-block">
                      Swaps
                    </a>
                    <a className="[text-decoration:none] relative text-[inherit] inline-block min-w-[38px]">
                      Spray
                    </a>
                  </div>
                  <div className="w-[79px] flex flex-col items-start justify-start pt-0 px-0 pb-7 box-border gap-[18px]">
                    <div className="self-stretch relative text-base text-font-color-white">
                      Resources
                    </div>
                    <a className="[text-decoration:none] relative text-[inherit] inline-block min-w-[30px]">
                      Blog
                    </a>
                    <a className="[text-decoration:none] relative text-[inherit] inline-block min-w-[28px]">
                      FAQ
                    </a>
                    <a className="[text-decoration:none] relative text-[inherit] inline-block min-w-[54px]">
                      Support
                    </a>
                  </div>
                  <div className="h-[152px] flex flex-col items-start justify-start gap-[18px] text-base text-font-color-white">
                    <a className="[text-decoration:none] relative text-[inherit] inline-block min-w-[71px]">
                      Company
                    </a>
                    <a className="[text-decoration:none] relative text-sm text-darkgray-300 inline-block min-w-[62px]">
                      About Us
                    </a>
                  </div>
                  <div className="w-[117px] flex flex-col items-start justify-start pt-0 px-0 pb-7 box-border gap-[18px]">
                    <a className="[text-decoration:none] relative text-base text-font-color-white inline-block min-w-[41px]">
                      Legal
                    </a>
                    <a className="[text-decoration:none] relative text-[inherit] inline-block min-w-[91px]">
                      Privacy Policy
                    </a>
                    <div className="self-stretch relative">
                      Terms of Services
                    </div>
                    <a className="[text-decoration:none] relative text-[inherit] inline-block min-w-[53px]">
                      Cookies
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <FrameComponent1 />
        </div>
      </footer>
    </section>
  );
};

export default PoliciesConnect;
