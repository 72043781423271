import { FunctionComponent } from "react";
import HeroFeatures from "../components/HeroFeatures";
import RightContent from "../components/RightContent";
import FrameComponent5 from "../components/FrameComponent5";
import FrameComponent6 from "../components/FrameComponent6";
import JoinPartyGraphic from "../components/JoinPartyGraphic";
import FrameComponent7 from "../components/FrameComponent7";
import Section5Footer from "../components/Footer";
import Footer from "../components/Footer";
import React from "react";

const PeniwalletFeatures: FunctionComponent = () => {
  return (
    <div className="w-full relative bg-gray1-700 overflow-hidden flex flex-col items-end justify-start gap-[169px] leading-[normal] tracking-[normal] lg:gap-[84px] sm:gap-[42px]">
      <HeroFeatures />
      <RightContent />
      <FrameComponent5 />
      <FrameComponent6 />
      <FrameComponent7 />
      <Footer />
    </div>
  );
};

export default PeniwalletFeatures;
