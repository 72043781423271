import { FunctionComponent } from "react";
import { useSpring, animated } from 'react-spring';
import { useInView } from 'react-intersection-observer';
import React from "react";
export type EnjoyWeb3Type = {
  className?: string;
};

const EnjoyWeb3: FunctionComponent<EnjoyWeb3Type> = ({
  className = "",
}) => {
  const [refLeft, inViewLeft] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  const [refRight, inViewRight] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  
  const [refBottom, inViewBottom] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  const [refAboutLeft, inViewAboutLeft] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  const [refAboutRight, inViewAboutRight] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  const [refAboutBottom, inViewAboutBottom] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  
  const animation = useSpring({
    opacity: 1,
    transform: 'translateY(0)',
    from: { opacity: 0, transform: 'translateY(50px)' },
    config: { duration: 1000 },
  });
  const animationLeft = useSpring({
    opacity: inViewLeft ? 1 : 0,
    transform: inViewLeft ? 'translateX(0)' : 'translateX(-50px)', // Translate from left
    config: { duration: 700 },
  });
  
  const animationRight = useSpring({
    opacity: inViewRight ? 1 : 0,
    transform: inViewRight ? 'translateX(0)' : 'translateX(50px)', // Translate from right
    config: { duration: 700 },
  });
  
  const animationAbout = useSpring({
    opacity: 1,
    transform: 'translateY(0)',
    from: { opacity: 0, transform: 'translateY(50px)' },
    config: { duration: 700 },
  });
  const animationAboutLeft = useSpring({
    opacity: inViewAboutLeft ? 1 : 0,
    transform: inViewAboutLeft ? 'translateX(0)' : 'translateX(-50px)', // Translate from left
    config: { duration: 700 },
  });
  
  const animationAboutRight = useSpring({
    opacity: inViewAboutRight ? 1 : 0,
    transform: inViewAboutRight ? 'translateX(0)' : 'translateX(50px)', // Translate from right
    config: { duration: 700 },
  });
  const animationAboutBottom = useSpring({
    opacity: inViewAboutBottom ? 1 : 0,
    transform: inViewAboutBottom ? 'translateY(0)' : 'translateY(50px)',
    config: { duration: 700 }, // Adjust animation duration
  });
  const bottomAnimation = useSpring({
    opacity: inViewBottom ? 1 : 0,
    transform: inViewBottom ? 'translateY(0)' : 'translateY(50px)',
    config: { duration: 700 }, // Adjust animation duration
  });
  return (
    <section
      className={`self-stretch h-screen md:h-auto flex flex-row items-start bg-[#090818]  justify-center py-0 px-32 md:px-6 box-border w-full text-center text-45xl text-gainsboro-300 font-polysans-trial ${className}`}
    >
      <animated.div ref={refAboutBottom} style={animationAboutBottom}  className="self-stretch  overflow-hidden flex flex-row flex-wrap items-center justify-center pt-[66.1px] pb-[21.1px] box-border gap-[20px] max-w-full text-whitesmoke-100 md:pt-[43px] md:pb-5 md:box-border">
            <div className="flex-1 flex flex-col items-start justify-start gap-[35.8px] w-full md:gap-[18px] md:w-full">
              <div className="self-stretch rounded-[21.69px] flex flex-col items-start justify-start py-0  pl-0 box-border gap-[50px] w-full md:gap-[25px]">
                
              <h1 className="m-0 self-stretch relative text-27xl font-normal font-inherit text-left sm:text-19xl sm:leading-[45px] md:text-32xl md:leading-[60px]">
              <span>
                <p className="m-0">Enjoy a Web3 experience </p>
                <p className="m-0">powered by community</p>
              </span>
            </h1>
              </div>
              <h3 className="m-0 w-full relative text-base tracking-[-0.03em] text-left  font-normal font-poppins text-dimgray-200 inline-block shrink-0 max-w-full sm:text-base sm:leading-[27px]">
              Join our vibrant and diverse community to learn about the power of
              self-custody, crypto, and Web3.
              </h3>
              <button className="cursor-pointer [border:none] py-[16.5px] px-7 bg-royalblue-100 rounded-13xl flex flex-row items-start justify-start whitespace-nowrap z-[1] hover:bg-dodgerblue">
              <div className="relative text-smi tracking-[-0.01em] leading-[24px] font-polysans-trial text-font-color-white text-left">
                Join our Community
              </div>
            </button>
            </div>
            <div className="flex-1 flex flex-col items-start justify-end pt-0 px-0 pb-[27.7px] box-border min-w-[383px] max-w-full md:min-w-full">
              <img
                className="self-stretch h-[351.4px] relative max-w-full overflow-hidden shrink-0"
                loading="lazy"
                alt=""
                src="/enjoy-web3.svg"
              />
            </div>
          </animated.div>
    </section>
  );
};

export default EnjoyWeb3;
