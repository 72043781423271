import { FunctionComponent } from "react";
import FrameComponent10 from "../components/FrameComponent10";
import FrameComponent11 from "../components/FrameComponent11";
import PoliciesConnect from "../components/PoliciesConnect";
import React from "react";

const PeniwalletPrivacyPolicy: FunctionComponent = () => {
  return (
    <div className="w-full relative bg-gray1-700 overflow-hidden flex flex-col items-start justify-start gap-[81.8px] leading-[normal] tracking-[normal] sm:gap-[20px] md:gap-[41px]">
      <FrameComponent10 />
      <section className="w-[1716px] flex flex-row items-start justify-center pt-0 px-5 pb-7 box-border max-w-full text-left text-xl text-neutral-03 font-poppins">
        <div className="w-[1268px] flex flex-col items-start justify-start gap-[16.2px] max-w-full">
          <div className="self-stretch flex flex-row items-start justify-start relative max-w-full text-center text-45xl text-font-color-white font-text-sm-normal">
            <div className="h-0 w-0 absolute !m-[0] top-[197px] left-[0px] leading-[120.31%] capitalize inline-block sm:text-19xl sm:leading-[46px] md:text-32xl md:leading-[62px]" />
            <div className="flex-1 flex flex-col items-start justify-start gap-[51px] max-w-full z-[1] text-left text-5xl text-neutral-03 font-poppins sm:gap-[25px]">
              <div className="self-stretch flex flex-col items-start justify-start gap-[21px] max-w-full">
                <div className="self-stretch relative text-lg leading-[28px] font-medium text-aliceblue-100">
                  <p className="m-0">
                    Hello, and welcome to Peniwallet! I'm excited to provide you
                    with an overview of our decentralized crypto wallet
                    platform. At Peniwallet, we prioritize your privacy and
                    security, ensuring a seamless and secure experience in
                    managing your digital assets.
                  </p>
                  <p className="m-0"> </p>
                  <p className="m-0">
                    As users ourselves, we understand the importance of
                    safeguarding personal information. Peniwallet operates on a
                    decentralized model, eliminating the need for collecting
                    your personal data. Our commitment is to empower you with a
                    user-centric, privacy-focused platform for all your
                    cryptocurrency needs.
                  </p>
                  <p className="m-0"> </p>
                </div>
                <div className="self-stretch flex flex-col items-start justify-start max-w-full text-center">
                  <b className="relative leading-[86px] inline-block max-w-full sm:text-lgi sm:leading-[69px]">
                    Purpose of the Privacy Policy
                  </b>
                  <div className="self-stretch relative text-lg leading-[28px] font-medium text-aliceblue-100 text-left">
                    In crafting this Privacy Policy, our primary goal is to
                    transparently communicate how Peniwallet respects and
                    protects your privacy. As users, you deserve clarity on the
                    information we collect, how it's utilized, and the security
                    measures we implement. This document serves as a commitment
                    to ensuring your trust, detailing the principles guiding our
                    approach to privacy within the decentralized Peniwallet
                    ecosystem.
                  </div>
                </div>
                <div className="self-stretch flex flex-col items-start justify-start max-w-full">
                  <b className="relative leading-[86px] inline-block max-w-full sm:text-lgi sm:leading-[69px]">
                    Commitment to User Privacy
                  </b>
                  <div className="self-stretch relative text-lg leading-[28px] font-medium text-aliceblue-100">
                    Our commitment to your privacy forms the core foundation of
                    Peniwallet's ethos. As users ourselves, we understand the
                    significance of trust in the digital realm. Rest assured,
                    Peniwallet is dedicated to upholding the highest standards
                    of user privacy. We pledge to continually innovate and
                    implement measures that prioritize the confidentiality and
                    security of your data. Your trust is paramount, and we
                    strive to earn and maintain it through our unwavering
                    commitment to safeguarding your personal information within
                    the decentralized framework of Peniwallet.
                  </div>
                </div>
              </div>
              <div className="self-stretch flex flex-col items-start justify-start gap-[26px] max-w-full text-xl">
                <div className="flex flex-col items-start justify-start max-w-full text-5xl">
                  <b className="relative leading-[53px] capitalize sm:text-lgi sm:leading-[42px]">
                    Information Collected
                  </b>
                  <b className="h-[59px] relative leading-[86px] capitalize flex items-center shrink-0 sm:text-lgi sm:leading-[69px]">
                    Non-personal Information
                  </b>
                </div>
                <div className="self-stretch flex flex-col items-start justify-start gap-[27px] max-w-full">
                  <b className="h-3.5 relative leading-[86px] inline-block max-w-full sm:text-base sm:leading-[69px]">
                    Technical data (e.g, device information, IP addresses)
                  </b>
                  <div className="self-stretch h-[140px] relative text-lg leading-[28px] font-medium text-aliceblue-100 inline-block shrink-0">
                    In the course of your Peniwallet experience, we may collect
                    technical data to enhance the platform's functionality. This
                    includes information about your device and IP addresses,
                    enabling us to optimize performance, troubleshoot issues,
                    and better understand user preferences. It's important to
                    note that this data is non-personal, ensuring your anonymity
                    while contributing to the continual improvement of
                    Peniwallet's services. We remain committed to treating this
                    technical information with the utmost respect for your
                    privacy.
                  </div>
                </div>
                <div className="self-stretch flex flex-col items-start justify-start gap-[27px] max-w-full">
                  <b className="h-3.5 relative leading-[86px] inline-block max-w-full sm:text-base sm:leading-[69px]">
                    Analytical Data (e.g., usage patterns, preferences)
                  </b>
                  <div className="self-stretch relative text-lg leading-[28px] font-medium text-aliceblue-100">
                    <p className="m-0">
                      To enhance your Peniwallet experience, we analyze
                      non-personal data such as usage patterns and preferences.
                      This information aids in tailoring our services to better
                      suit your needs and preferences. By understanding how
                      users interact with Peniwallet, we can continually refine
                      and optimize the platform. Rest assured, this analytical
                      data is anonymized, prioritizing your privacy and
                      contributing to the ongoing improvement of your
                      decentralized wallet experience.
                    </p>
                    <p className="m-0"> </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="self-stretch flex flex-col items-start justify-start pt-0 px-0 pb-[38.8px] box-border gap-[27px] max-w-full text-5xl">
            <div className="flex flex-col items-start justify-start gap-[2.8px] max-w-full">
              <b className="h-20 relative leading-[86px] capitalize inline-block sm:text-lgi sm:leading-[69px]">
                User control and Consent
              </b>
              <b className="h-3.5 relative text-xl leading-[86px] inline-block sm:text-base sm:leading-[69px]">
                Transparency in Data Collection
              </b>
            </div>
            <div className="self-stretch relative text-lg leading-[28px] font-medium text-aliceblue-100">
              At Peniwallet, we believe in empowering users through
              transparency. We are committed to providing clear and accessible
              information regarding the collection of data. Before any
              information is gathered, you will be informed about the types of
              data involved and the purposes behind it. This commitment ensures
              that you have full visibility into the data collection process,
              enabling you to make informed decisions about your privacy within
              the Peniwallet ecosystem. Your control and understanding are
              paramount.
            </div>
          </div>
          <div className="self-stretch flex flex-col items-start justify-start pt-0 px-0 pb-[51.8px] gap-[27px]">
            <b className="h-3.5 relative leading-[86px] inline-block sm:text-base sm:leading-[69px]">
              Opt-in Mechanisms
            </b>
            <div className="self-stretch relative text-lg leading-[28px] font-medium text-aliceblue-100">
              We respect your autonomy over your data. Peniwallet operates on an
              opt-in mechanism, meaning we seek your explicit consent before
              collecting any personal information. Whether it's enabling
              specific features or enhancing your overall experience, you are in
              control. This approach ensures that your participation in data
              collection is a conscious choice, reinforcing our commitment to
              putting you in the driver's seat of your Peniwallet experience.
            </div>
          </div>
          <div className="self-stretch flex flex-col items-start justify-start gap-[27px]">
            <b className="h-3.5 relative leading-[86px] inline-block sm:text-base sm:leading-[69px]">
              Revocation of Consent
            </b>
            <div className="self-stretch relative text-lg leading-[28px] font-medium text-aliceblue-100">
              Your control extends beyond initial consent at Peniwallet. We
              understand that preferences may evolve. You have the right to
              revoke your consent at any time. Whether you decide to opt-out of
              specific data collection features or withdraw consent altogether,
              Peniwallet respects your choices. We've implemented
              straightforward mechanisms to make the process simple and ensure
              that your privacy remains under your control throughout your
              journey with Peniwallet.
            </div>
          </div>
        </div>
      </section>
      <section className="w-[1716px] flex flex-row items-start justify-center pt-0 px-5 pb-[15px] box-border max-w-full text-left text-xl text-neutral-03 font-poppins">
        <div className="w-[1268px] flex flex-col items-start justify-start gap-[55px] max-w-full sm:gap-[27px]">
          <div className="self-stretch flex flex-col items-start justify-start gap-[27px] text-5xl">
            <div className="flex flex-col items-start justify-start">
              <b className="relative leading-[87.6px] capitalize sm:text-lgi sm:leading-[69px]">
                Data Security Measures
              </b>
              <b className="h-3.5 relative text-xl leading-[86px] inline-block mt-[-3.4px] sm:text-base sm:leading-[69px]">
                Encryption Standards
              </b>
            </div>
            <div className="self-stretch relative text-lg leading-[28px] font-medium text-aliceblue-100">
              Securing your data is paramount at Peniwallet. We employ robust
              encryption standards to safeguard your information during
              transmission and storage. Our commitment to using state-of-the-art
              encryption technologies ensures that your transactions and
              sensitive data are protected against unauthorized access. Rest
              easy, knowing that Peniwallet is dedicated to maintaining the
              highest standards of security to fortify your confidence in using
              our decentralized wallet platform.
            </div>
          </div>
          <div className="self-stretch flex flex-col items-start justify-start pt-0 px-0 pb-[13px] gap-[27px]">
            <b className="h-3.5 relative leading-[86px] inline-block sm:text-base sm:leading-[69px]">
              Secure Transaction Protocols
            </b>
            <div className="self-stretch relative text-lg leading-[28px] font-medium text-aliceblue-100">
              At Peniwallet, we prioritize the security of your transactions.
              Our platform utilizes secure transaction protocols to safeguard
              the integrity and confidentiality of your financial interactions.
              By implementing industry-leading encryption and authentication
              measures, we ensure that each transaction within Peniwallet is
              conducted with the highest level of security. Trust in the
              reliability of our secure protocols as you navigate the world of
              decentralized finance with Peniwallet.
            </div>
          </div>
          <div className="self-stretch flex flex-col items-start justify-start gap-[27px]">
            <b className="h-3.5 relative leading-[86px] inline-block sm:text-base sm:leading-[69px]">
              Regular Security Audits
            </b>
            <div className="self-stretch relative text-lg leading-[28px] font-medium text-aliceblue-100">
              Ensuring the ongoing integrity of Peniwallet's security is a top
              priority. We conduct regular security audits to assess and fortify
              the robustness of our systems. These audits help identify
              potential vulnerabilities and ensure compliance with the latest
              security standards. Your peace of mind is our focus, and the
              commitment to regular security audits underscores our dedication
              to maintaining a secure environment for your financial
              transactions and data within Peniwallet.
            </div>
          </div>
        </div>
      </section>
      <section className="w-[1716px] flex flex-row items-start justify-center pt-0 px-5 pb-[28.2px] box-border max-w-full text-left text-xl text-neutral-03 font-poppins">
        <div className="w-[1268px] flex flex-col items-start justify-start gap-[55px] max-w-full sm:gap-[27px]">
          <div className="self-stretch flex flex-col items-start justify-start gap-[27px] max-w-full text-5xl">
            <div className="flex flex-col items-start justify-start gap-[2.8px] max-w-full">
              <b className="h-[81px] relative leading-[86px] capitalize inline-block sm:text-lgi sm:leading-[69px]">
                Decentralized Nature of Peniwallet
              </b>
              <b className="h-3.5 relative text-xl leading-[86px] inline-block z-[1] sm:text-base sm:leading-[69px]">
                Explanation of Decentralized Architecture
              </b>
            </div>
            <div className="self-stretch relative text-lg leading-[28px] font-medium text-aliceblue-100">
              Peniwallet operates on a decentralized architecture, meaning
              there's no central authority or single point of control. This
              unique structure empowers users by eliminating the need for a
              central repository of personal information. In a decentralized
              model, you retain full control over your data, transactions, and
              digital assets. This innovative approach not only enhances your
              privacy but also aligns with the core principles of transparency
              and user autonomy at the heart of Peniwallet.
            </div>
          </div>
          <div className="self-stretch flex flex-col items-start justify-start pt-0 px-0 pb-[13px] box-border gap-[27px] max-w-full">
            <b className="h-3.5 relative leading-[86px] inline-block max-w-full sm:text-base sm:leading-[69px]">
              No Collection of Personal Information
            </b>
            <div className="self-stretch relative text-lg leading-[28px] font-medium text-aliceblue-100">
              Peniwallet takes pride in its commitment to privacy. As a
              decentralized wallet platform, we do not collect personal
              information. Your privacy is paramount, and our architecture is
              designed to operate without the need for storing or accessing your
              personal data. This fundamental aspect ensures that your financial
              transactions and digital assets remain exclusively under your
              control, emphasizing Peniwallet's dedication to providing a secure
              and privacy-focused environment for all users.
            </div>
          </div>
          <div className="self-stretch flex flex-col items-start justify-start gap-[27px]">
            <b className="h-3.5 relative leading-[86px] inline-block sm:text-base sm:leading-[69px]">
               User Anonymity
            </b>
            <div className="self-stretch relative text-lg leading-[28px] font-medium text-aliceblue-100">
              Embracing the principles of decentralization, Peniwallet
              prioritizes user anonymity. Within our platform, transactions and
              interactions are conducted without the need for revealing personal
              identities. Your privacy is upheld through pseudonymous addresses
              and cryptographic techniques, ensuring a level of anonymity that
              aligns with the core tenets of decentralized finance. Rest
              assured, Peniwallet empowers you with the freedom to manage your
              digital assets without compromising your identity or personal
              information.
            </div>
          </div>
        </div>
      </section>
      <section className="w-[1716px] flex flex-row items-start justify-center pt-0 px-5 pb-7 box-border max-w-full text-left text-5xl text-neutral-03 font-poppins">
        <div className="w-[1268px] flex flex-col items-start justify-start gap-[37.4px] max-w-full sm:gap-[19px]">
          <b className="relative leading-[87.6px] capitalize sm:text-lgi sm:leading-[69px]">
            Purpose of Data Usage
          </b>
          <div className="self-stretch flex flex-col items-start justify-start gap-[40px] max-w-full text-xl sm:gap-[20px]">
            <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-[15px] box-border max-w-full">
              <div className="flex-1 flex flex-row items-start justify-start relative max-w-full">
                <b className="absolute !m-[0] top-[-41px] left-[0px] leading-[86px] inline-block z-[1] sm:text-base sm:leading-[69px]">
                  Transaction Facilitation
                </b>
                <div className="flex-1 relative text-lg leading-[28px] font-medium text-aliceblue-100 inline-block max-w-full z-[2]">
                  One primary purpose of data usage at Peniwallet is the
                  facilitation of seamless transactions. The information we
                  process is directly related to ensuring the smooth execution
                  of your financial activities within the platform. From
                  confirming transaction details to enhancing transaction
                  security, the data collected serves the sole purpose of
                  optimizing and facilitating your experience as you engage in
                  decentralized financial transactions through Peniwallet.
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-col items-start justify-start gap-[27px]">
              <b className="h-3.5 relative leading-[86px] inline-block sm:text-base sm:leading-[69px]">
                Service Improvement
              </b>
              <div className="self-stretch relative text-lg leading-[28px] font-medium text-aliceblue-100">
                Your experience matters, and at Peniwallet, we utilize data to
                continually enhance our services. By analyzing usage patterns
                and preferences, we gain valuable insights that guide
                improvements to the platform. This iterative approach ensures
                that Peniwallet evolves to meet your changing needs, providing
                you with an increasingly refined and user-friendly decentralized
                wallet experience. The data collected for service improvement is
                handled with utmost care, always with the goal of delivering an
                exceptional and tailored experience for every user.
              </div>
            </div>
            <div className="self-stretch flex flex-col items-start justify-start gap-[27px]">
              <b className="h-3.5 relative leading-[86px] inline-block sm:text-base sm:leading-[69px]">
                Legal Compliance
              </b>
              <div className="self-stretch relative text-lg leading-[28px] font-medium text-aliceblue-100">
                Peniwallet is committed to upholding legal standards and
                regulatory requirements. The data collected is used for purposes
                of compliance with applicable laws and regulations governing
                financial transactions and user privacy. This ensures that
                Peniwallet operates within the legal framework, providing you
                with a trustworthy and law-abiding decentralized platform for
                managing your digital assets. Rest assured, your data is handled
                responsibly and in accordance with the legal landscape to
                maintain the integrity of Peniwallet's services.
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="w-[1716px] flex flex-row items-start justify-center pt-0 px-5 pb-7 box-border max-w-full text-left text-5xl text-neutral-03 font-poppins">
        <div className="w-[1268px] flex flex-col items-start justify-start gap-[40px] max-w-full sm:gap-[20px]">
          <div className="self-stretch flex flex-col items-start justify-start gap-[27px]">
            <div className="flex flex-col items-start justify-start">
              <b className="relative leading-[87.6px] capitalize sm:text-lgi sm:leading-[69px]">
                Third-party Services
              </b>
              <b className="h-3.5 relative text-xl leading-[86px] inline-block min-w-[116px] z-[1] mt-[-3.4px] sm:text-base sm:leading-[69px]">
                Integration
              </b>
            </div>
            <div className="self-stretch relative text-lg leading-[28px] font-medium text-aliceblue-100">
              Peniwallet may integrate with third-party services to enhance your
              experience. This integration is designed to provide additional
              functionalities and features within the platform. Our commitment
              is to transparently communicate any third-party involvement,
              ensuring you are informed about the nature and purpose of these
              integrations. While these services may bring added value, rest
              assured that Peniwallet maintains stringent standards to protect
              your privacy and ensure the seamless integration of third-party
              services aligns with our commitment to user security.
            </div>
          </div>
          <div className="self-stretch flex flex-col items-start justify-start gap-[27px] max-w-full text-xl">
            <b className="h-3.5 relative leading-[86px] inline-block max-w-full sm:text-base sm:leading-[69px]">
              Privacy Assurance from Third-Party Providers
            </b>
            <div className="self-stretch relative text-lg leading-[28px] font-medium text-aliceblue-100">
              When Peniwallet integrates with third-party services, your privacy
              remains a top priority. We collaborate only with reputable
              partners who share our commitment to stringent privacy standards.
              Our assurance is that any data shared with third-party providers
              is done securely, adhering to the same level of privacy protection
              you expect from Peniwallet. We prioritize partnerships that
              enhance your experience without compromising the confidentiality
              and security of your information within our decentralized wallet
              platform.
            </div>
          </div>
        </div>
      </section>
      <section className="w-[1716px] flex flex-row items-start justify-center py-0 px-5 box-border max-w-full text-left text-5xl text-neutral-03 font-poppins">
        <div className="w-[1268px] flex flex-col items-start justify-start gap-[27px] max-w-full">
          <div className="flex flex-col items-start justify-start gap-[2.8px] max-w-full">
            <b className="h-[81px] relative leading-[86px] capitalize inline-block max-w-full sm:text-lgi sm:leading-[69px]">
              International data transfer
            </b>
            <b className="h-3.5 relative text-xl leading-[86px] inline-block z-[1] sm:text-base sm:leading-[69px]">
              Cross-Border Data Flow Explanation (Not Operating in the United
              States and China)
            </b>
          </div>
          <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-[13px] box-border max-w-full text-lg text-aliceblue-100">
            <div className="flex-1 relative leading-[28px] font-medium inline-block max-w-full">
              Peniwallet acknowledges the significance of international data
              transfers and wants to clarify our operating scope. We do not
              operate in regions such as the United States and China. This
              deliberate decision is made to align with specific regulatory
              considerations and to ensure that data is processed and stored in
              jurisdictions where Peniwallet can uphold the highest standards of
              user privacy and comply with applicable data protection laws. This
              strategic approach reflects our commitment to safeguarding your
              data throughout its journey within the Peniwallet ecosystem.
            </div>
          </div>
          <div className="self-stretch flex flex-col items-start justify-start gap-[27px] max-w-full text-xl">
            <b className="h-3.5 relative leading-[86px] inline-block max-w-full sm:text-base sm:leading-[69px]">
              Compliance with Data Protection Laws
            </b>
            <div className="self-stretch relative text-lg leading-[28px] font-medium text-aliceblue-100">
              Peniwallet is dedicated to strict adherence to data protection
              laws across jurisdictions where we operate. Recognizing the global
              nature of digital transactions, we ensure that any cross-border
              data transfers comply with applicable data protection regulations.
              By aligning our practices with international standards, Peniwallet
              reinforces its commitment to protecting your privacy, regardless
              of geographic boundaries. Your data is handled with the utmost
              care and in full compliance with the laws governing data
              protection in the regions where Peniwallet operates.
            </div>
          </div>
        </div>
      </section>
      <section className="w-[1716px] flex flex-row items-start justify-center pt-0 px-5 pb-7 box-border max-w-full text-left text-xl text-neutral-03 font-poppins">
        <div className="w-[1268px] flex flex-col items-start justify-start gap-[68px] max-w-full sm:gap-[17px] sm:gap-[34px]">
          <div className="self-stretch flex flex-col items-start justify-start gap-[27px] text-5xl">
            <div className="flex flex-col items-start justify-start">
              <b className="relative leading-[87.8px] capitalize sm:text-lgi sm:leading-[69px]">
                user rights
              </b>
              <b className="h-3.5 relative text-xl leading-[86px] inline-block z-[1] mt-[-3.6px] sm:text-base sm:leading-[69px]">
                Access to Personal Data
              </b>
            </div>
            <div className="self-stretch relative text-lg leading-[28px] font-medium text-aliceblue-100">
              At Peniwallet, we want to be transparent about our data practices.
              As we operate on a decentralized model and do not collect personal
              data, there is no personal data associated with your account.
              Consequently, the concept of providing access to personal data
              does not apply within the Peniwallet ecosystem. Our commitment to
              user privacy means you can enjoy the benefits of a decentralized
              wallet without the need to worry about access to or retrieval of
              personal data.
            </div>
          </div>
          <div className="self-stretch flex flex-col items-start justify-start gap-[27px] max-w-full">
            <b className="h-3.5 relative leading-[86px] inline-block max-w-full sm:text-base sm:leading-[69px]">
              Correction and Deletion Requests
            </b>
            <div className="self-stretch relative text-lg leading-[28px] font-medium text-aliceblue-100">
              Even though Peniwallet does not collect personal data, we
              understand the importance of user control. If you have any
              concerns or discover inaccuracies related to non-personal data, we
              provide mechanisms for correction or deletion. Your experience
              with Peniwallet should be tailored to your preferences, and we are
              committed to accommodating any adjustments necessary to ensure the
              accuracy and relevance of the information associated with your
              decentralized wallet account.
            </div>
          </div>
          <div className="self-stretch flex flex-col items-start justify-start gap-[27px]">
            <b className="h-3.5 relative leading-[86px] inline-block sm:text-base sm:leading-[69px]">
              Data Portability Options
            </b>
            <div className="self-stretch relative text-lg leading-[28px] font-medium text-aliceblue-100">
              While Peniwallet does not involve the collection of personal data,
              we recognize the importance of user control and flexibility. If
              there are any non-personal data aspects you wish to export or
              transfer, we provide options for data portability within the
              boundaries of our decentralized model. Your ability to manage and
              control the data associated with your Peniwallet account remains a
              priority, even in the absence of traditional personal data
              collection.
            </div>
          </div>
        </div>
      </section>
      <section className="w-[1716px] flex flex-row items-start justify-center pt-0 px-5 pb-7 box-border max-w-full text-left text-5xl text-neutral-03 font-poppins">
        <div className="w-[1268px] flex flex-col items-start justify-start gap-[27px] max-w-full">
          <div className="flex flex-col items-start justify-start">
            <b className="relative leading-[87.6px] capitalize sm:text-lgi sm:leading-[69px]">
              Data retention policy
            </b>
            <b className="h-3.5 relative text-xl leading-[86px] inline-block z-[1] mt-[-3.4px] sm:text-base sm:leading-[69px]">
              Clear Retention Periods
            </b>
          </div>
          <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-[13px] box-border max-w-full text-lg text-aliceblue-100">
            <div className="flex-1 relative leading-[28px] font-medium inline-block max-w-full">
              In line with our commitment to privacy and transparency,
              Peniwallet operates with clear data retention practices. Since we
              do not collect personal data, there are no retention periods
              associated with individual user information. As part of our
              decentralized approach, Peniwallet ensures that any non-personal
              data is retained only for the necessary duration to facilitate
              your transactions and enhance the functionality of the platform.
              This commitment reflects our dedication to minimizing data
              retention and upholding your privacy within the Peniwallet
              ecosystem.
            </div>
          </div>
          <div className="self-stretch flex flex-col items-start justify-start gap-[27px] max-w-full text-xl">
            <b className="h-3.5 relative leading-[86px] inline-block max-w-full sm:text-base sm:leading-[69px]">
              Deletion of Inactive Accounts (Decentralized Model)
            </b>
            <div className="self-stretch relative text-lg leading-[28px] font-medium text-aliceblue-100">
              In our decentralized model, Peniwallet operates without the
              conventional concept of account deletion, as there is no central
              repository for account information. Users maintain control over
              their decentralized wallets, and the absence of personal data
              collection means there's no need for account deletion procedures.
              Your digital assets and transactions are securely managed without
              reliance on a central authority, aligning with the decentralized
              principles at the core of Peniwallet's design.
            </div>
          </div>
        </div>
      </section>
      <section className="w-[1716px] flex flex-row items-start justify-center pt-0 px-5 pb-7 box-border max-w-full text-left text-5xl text-neutral-03 font-poppins">
        <div className="w-[1268px] flex flex-col items-start justify-start gap-[68px] max-w-full sm:gap-[17px] sm:gap-[34px]">
          <div className="self-stretch flex flex-col items-start justify-start gap-[27px] max-w-full">
            <div className="flex flex-col items-start justify-start max-w-full">
              <b className="relative leading-[87.6px] capitalize sm:text-lgi sm:leading-[69px]">
                updates to the privacy policy
              </b>
              <b className="h-3.5 relative text-xl leading-[86px] inline-block z-[1] mt-[-3.4px] sm:text-base sm:leading-[69px]">
                Notification Mechanisms
              </b>
            </div>
            <div className="self-stretch relative text-lg leading-[28px] font-medium text-aliceblue-100">
              Peniwallet is committed to keeping you informed about any updates
              to our Privacy Policy. In the event of changes, we will provide
              clear and timely notifications through appropriate channels within
              the Peniwallet platform. This ensures that you stay informed about
              any modifications to our privacy practices, empowering you to make
              informed decisions and maintain control over your data within the
              evolving landscape of decentralized finance. Your privacy and
              understanding are our top priorities.
            </div>
          </div>
          <div className="self-stretch flex flex-col items-start justify-start gap-[27px] max-w-full text-xl">
            <b className="h-3.5 relative leading-[86px] inline-block max-w-full sm:text-base sm:leading-[69px]">
               User Agreement to Policy Changes
            </b>
            <div className="self-stretch relative text-lg leading-[28px] font-medium text-aliceblue-100">
              Your active participation in the Peniwallet community is crucial,
              especially when it comes to privacy policy changes. We request
              your agreement to any updated policies, ensuring transparency and
              user consent. By continuing to use Peniwallet following the
              implementation of changes, you signify your acceptance of the
              revised privacy terms. This collaborative approach empowers you
              with the knowledge and choice to align with the latest
              developments in our commitment to privacy within the decentralized
              Peniwallet platform.
            </div>
          </div>
        </div>
      </section>
      <section className="w-[1716px] flex flex-row items-start justify-center py-0 px-5 box-border max-w-full text-left text-5xl text-neutral-03 font-poppins">
        <div className="w-[1268px] flex flex-col items-start justify-start gap-[68px] max-w-full sm:gap-[17px] sm:gap-[34px]">
          <div className="self-stretch flex flex-col items-start justify-start gap-[27px] max-w-full">
            <div className="flex flex-col items-start justify-start gap-[2.8px] max-w-full">
              <b className="h-[81px] relative leading-[86px] capitalize inline-block sm:text-lgi sm:leading-[69px]">
                contact inofrmation
              </b>
              <b className="h-3.5 relative text-xl leading-[86px] inline-block z-[1] sm:text-base sm:leading-[69px]">
                Dedicated Privacy Support Channel
              </b>
            </div>
            <div className="self-stretch relative text-lg leading-[28px] font-medium text-aliceblue-100">
              At Peniwallet, we value your privacy concerns. For any questions
              or assistance related to privacy matters, we provide a dedicated
              support channel. Reach out to our Privacy Support team through the
              specified contact information, ensuring that your inquiries
              receive prompt and focused attention. Your privacy is important to
              us, and our dedicated support channel is here to address any
              privacy-related queries or concerns you may have within the
              Peniwallet environment.
            </div>
          </div>
          <div className="self-stretch flex flex-col items-start justify-start gap-[27px] text-xl">
            <b className="h-3.5 relative leading-[86px] inline-block sm:text-base sm:leading-[69px]">
              Reporting Privacy Concerns
            </b>
            <div className="self-stretch relative text-lg leading-[28px] font-medium text-aliceblue-100">
              If you have privacy concerns or encounter any issues related to
              the protection of your data within Peniwallet, we encourage you to
              report them promptly. Our commitment to user privacy extends to
              actively addressing and resolving any privacy-related concerns.
              Utilize our designated reporting channels to inform us of any
              issues, allowing us to investigate and take appropriate actions to
              maintain the highest standards of privacy and security within the
              Peniwallet platform. Your cooperation is invaluable in ensuring a
              secure and privacy-focused experience for all users.
            </div>
          </div>
        </div>
      </section>
      <section className="w-[1716px] flex flex-row items-start justify-center py-0 px-5 box-border max-w-full text-left text-5xl text-neutral-03 font-poppins">
        <div className="w-[1268px] flex flex-col items-start justify-start gap-[68px] max-w-full sm:gap-[17px] sm:gap-[34px]">
          <div className="self-stretch flex flex-col items-start justify-start gap-[27px] max-w-full">
            <div className="flex flex-col items-start justify-start max-w-full">
              <b className="relative leading-[87.8px] capitalize sm:text-lgi sm:leading-[69px]">
                Legal compliance
              </b>
              <b className="h-3.5 relative text-xl leading-[86px] inline-block z-[1] mt-[-3.6px] sm:text-base sm:leading-[69px]">
                Adherence to Applicable Privacy Laws
              </b>
            </div>
            <div className="self-stretch relative text-lg leading-[28px] font-medium text-aliceblue-100">
              Peniwallet operates with a commitment to legal compliance across
              major jurisdictions, including the European Union and others where
              applicable. Despite not being present in the United States or
              China, we adhere to relevant privacy laws to ensure that our
              practices align with international standards. This commitment
              underscores our dedication to upholding user privacy and complying
              with the legal frameworks that govern data protection, creating a
              secure and trustworthy environment for all Peniwallet users.
            </div>
          </div>
          <div className="self-stretch flex flex-col items-start justify-start gap-[27px] max-w-full text-xl">
            <b className="h-3.5 relative leading-[86px] inline-block max-w-full sm:text-base sm:leading-[69px]">
              Cooperation with Regulatory Authorities
            </b>
            <div className="self-stretch relative text-lg leading-[28px] font-medium text-aliceblue-100">
              In our commitment to legal compliance, Peniwallet pledges
              cooperation with regulatory authorities. We recognize the
              importance of working transparently with relevant bodies to ensure
              alignment with privacy and data protection regulations. This
              cooperative approach strengthens our commitment to maintaining the
              highest standards of legality and ethics in the decentralized
              financial landscape. By collaborating with regulatory authorities,
              Peniwallet aims to contribute to the ongoing development of a
              secure and compliant environment for users worldwide.
            </div>
          </div>
        </div>
      </section>
      <FrameComponent11 />
      <section className="w-[1716px] flex flex-row items-start justify-center pt-0 px-5 pb-[260.2px] box-border max-w-full text-left text-5xl text-neutral-03 font-poppins sm:pb-[169px] sm:box-border">
        <div className="w-[1268px] flex flex-col items-start justify-start gap-[27px] max-w-full">
          <div className="flex flex-col items-start justify-start gap-[2.8px]">
            <b className="h-[81px] relative leading-[86px] capitalize inline-block sm:text-lgi sm:leading-[69px]">
              Effective Date
            </b>
            <b className="h-3.5 relative text-xl leading-[86px] inline-block z-[1] sm:text-base sm:leading-[69px]">
              Date of Implementation
            </b>
          </div>
          <div className="self-stretch relative text-lg leading-[28px] font-medium text-aliceblue-100">
            This Privacy Policy is effective as of the date of Peniwallet's
            implementation. Any updates or modifications to the policy will also
            be effective as of their respective implementation dates. We
            encourage users to regularly review this Privacy Policy to stay
            informed about the latest practices and ensure continued alignment
            with Peniwallet's commitment to privacy within the decentralized
            wallet platform.
          </div>
        </div>
      </section>
      <PoliciesConnect />
    </div>
  );
};

export default PeniwalletPrivacyPolicy;
