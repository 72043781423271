import React, { FunctionComponent, useMemo, type CSSProperties } from "react";

export type DownloadInstructionsType = {
  className?: string;
  vuesaxlinearlikeShapes?: string;
  startEnjoyingOurServices?: string;
  enjoyOurUnlimitedServices?: string;

  /** Style props */
 /** Style props */
 propAlignSelf?: "auto" | "flex-start" | "flex-end" | "center" | "baseline" | "stretch";
 propWidth?: string | number;};

const DownloadInstructions: FunctionComponent<DownloadInstructionsType> = ({
  className = "",
  vuesaxlinearlikeShapes,
  startEnjoyingOurServices,
  enjoyOurUnlimitedServices,
  propAlignSelf,
  propWidth,
}) => {
  const downloadInstructionsStyle: CSSProperties = useMemo(() => {
    return {
      alignSelf: propAlignSelf,
      width: propWidth,
    };
  }, [propAlignSelf, propWidth]);

  return (
    <div
      className={`self-stretch flex flex-row items-start justify-start gap-[26px] text-left text-lg text-font-color-white font-poppins sm:flex-wrap ${className}`}
      style={downloadInstructionsStyle}
    >
      <div className="flex flex-col items-start justify-start pt-[15px] px-0 pb-0">
        <div className="w-11 h-11 rounded-[46.48px] bg-font-color-white flex flex-row items-center justify-center py-3.5 px-2.5 box-border">
          <img
            className="h-6 w-6 relative"
            loading="lazy"
            alt=""
            src={vuesaxlinearlikeShapes}
          />
        </div>
      </div>
      <div className="flex-1 flex flex-col items-start justify-start gap-[8px] min-w-[198px]">
        <div className="relative leading-[28px]">
          {startEnjoyingOurServices}
        </div>
        <div className="self-stretch relative text-smi tracking-[-0.03em] leading-[19px] text-gray-400">
          {enjoyOurUnlimitedServices}
        </div>
      </div>
    </div>
  );
};

export default DownloadInstructions;
