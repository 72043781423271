import { FunctionComponent } from "react";
import Transaction from "./Transaction";
import { useSpring, animated } from 'react-spring';
import { useInView } from 'react-intersection-observer';
import React from "react";
export type FeaturesContentType = {
  className?: string;
};

const FeaturesContent: FunctionComponent<FeaturesContentType> = ({
  className = "",
}) => {
  const [refLeft, inViewLeft] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  const [refRight, inViewRight] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  
  const [refBottom, inViewBottom] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  const [refAboutLeft, inViewAboutLeft] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  const [refAboutRight, inViewAboutRight] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  const [refAboutBottom, inViewAboutBottom] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  
  const animation = useSpring({
    opacity: 1,
    transform: 'translateY(0)',
    from: { opacity: 0, transform: 'translateY(50px)' },
    config: { duration: 1000 },
  });
  const animationLeft = useSpring({
    opacity: inViewLeft ? 1 : 0,
    transform: inViewLeft ? 'translateX(0)' : 'translateX(-50px)', // Translate from left
    config: { duration: 700 },
  });
  
  const animationRight = useSpring({
    opacity: inViewRight ? 1 : 0,
    transform: inViewRight ? 'translateX(0)' : 'translateX(50px)', // Translate from right
    config: { duration: 700 },
  });
  
  const animationAbout = useSpring({
    opacity: 1,
    transform: 'translateY(0)',
    from: { opacity: 0, transform: 'translateY(50px)' },
    config: { duration: 700 },
  });
  const animationAboutLeft = useSpring({
    opacity: inViewAboutLeft ? 1 : 0,
    transform: inViewAboutLeft ? 'translateX(0)' : 'translateX(-50px)', // Translate from left
    config: { duration: 700 },
  });
  
  const animationAboutRight = useSpring({
    opacity: inViewAboutRight ? 1 : 0,
    transform: inViewAboutRight ? 'translateX(0)' : 'translateX(50px)', // Translate from right
    config: { duration: 700 },
  });
  const animationAboutBottom = useSpring({
    opacity: inViewAboutBottom ? 1 : 0,
    transform: inViewAboutBottom ? 'translateY(0)' : 'translateY(50px)',
    config: { duration: 700 }, // Adjust animation duration
  });
  const bottomAnimation = useSpring({
    opacity: inViewBottom ? 1 : 0,
    transform: inViewBottom ? 'translateY(0)' : 'translateY(50px)',
    config: { duration: 700 }, // Adjust animation duration
  });
  return (
    <div
      className={`self-stretch flex flex-col items-center justify-start py-32 px-32 md:py-24 md:px-6  box-border relative w-full text-center text-45xl text-gainsboro-200 font-polysans-trial ${className}`}
    >
      <div className="w-full h-full absolute !m-[0] top-[0px] right-[0px] bottom-[0px] left-[0px] [background:linear-gradient(180deg,_rgba(26,_26,_44,_0.15),_rgba(7,_6,_20,_0.15))]" />
      <div className="w-2/3 flex flex-col items-start justify-start gap-[28px] md:w-full font-polysans-trial  mb-20">
      <h1 className="m-0 self-stretch relative text-27xl font-normal font-inherit text-center sm:text-19xl sm:leading-[45px] md:text-32xl md:leading-[60px]">            4 Power features you’re going to love.
          </h1>
          <div className="self-stretch flex flex-row items-start justify-start py-0 px-[57px] box-border max-w-full text-lg text-lightslategray-100 md:pl-7 md:pr-7 md:box-border">
            <div className="flex-1 relative text-base md:text-sm tracking-[-0.04em] leading-[149%] font-medium inline-block w-full z-[1] text-[#9394A1]">
              Enjoy a smooth mobile app and desktop experience with easy-to-use,
              powerful tools to support your entire Web3 journey.
            </div>
          </div>
        </div>

        <div className="gap-4 flex flex-col">
        <div className="self-stretch  flex flex-row items-center justify-center gap-2 w-full md:flex-wrap">
          <animated.div ref={refLeft} style={animationLeft} className="flex flex-col w-[60%] md:w-full h-96 rounded-md  [background:linear-gradient(180deg,_rgba(255,_255,_255,_0.03),_rgba(255,_255,_255,_0))] border-[2px] border-solid border-[#1A1A2C]  text-gray-400  overflow-hidden  items-start text-left justify-center  px-10 md:px-5  box-border gap-4 ">
             
                <img
                  className="absolute top-[0px] left-[0px] [transform:_rotate(-180deg)] z-0 opacity-50"
                  alt=""
                  src="/vector-bg.svg" 
                />
                 <img
                className="self-stretch h-48 md:object-contain relative w-full overflow-hidden shrink-0 object-contain"
                alt=""
                src="/gasless.svg"
              />
              <div className="self-stretch rounded-[21.69px] w-2/3 md:w-full flex flex-col items-start justify-start gap-2 -mt-4">
               <div className="flex flex-col">
               <p className="m-0 self-stretch relative text-3xl text-white  font-normal font-inherit ">
               Gasless
                </p>
                <p className="m-0 self-stretch relative text-3xl text-white  font-normal font-inherit ">
               Transaction
                </p>
               </div>
               <h3 className="m-0 self-stretch relative text-sm tracking-[-0.03em]    font-normal font-poppins text-gray-400  ">
               Experience gasless transactions with Peniwallets—swift, efficient payments with no extra fees, making your financial interactions seamless and cost-free.
              </h3>
              </div>
             
             
            </animated.div>
            <animated.div ref={refRight} style={animationRight} className="flex flex-col w-[40%] md:w-full h-96 rounded-md  [background:linear-gradient(180deg,_rgba(255,_255,_255,_0.03),_rgba(255,_255,_255,_0))] border-[2px] border-solid border-[#1A1A2C]  text-gray-400  overflow-hidden  items-start text-left  justify-start pt-10 md:pt-5  px-10 md:px-5  box-border gap-4 ">
            
            <img
                  className="absolute top-[0px] left-[0px] [transform:_rotate(-180deg)] z-0 opacity-50"
                  alt=""
                  src="/vector-bg.svg"
                />
              <div className="self-stretch rounded-[21.69px] flex flex-col items-start justify-start gap-[50px] md:gap-[25px]">
               
                <p className="m-0 self-stretch relative text-3xl text-white tracking-[-0.01em] leading-[38px] font-normal font-inherit ">
                Swap your Cryptocurrencies
                </p>
              </div>
              <h3 className="m-0 self-stretch relative text-sm tracking-[-0.03em] md:text-sm   font-normal font-poppins text-gray-400  ">
               Easily swap your cryptocurrencies with Peniwallet, swift, secure, and hassle-free transactions for all your crypto needs
              </h3>
             
              <img
                className="self-stretch h-96 md:object-cover z-10 relative w-full overflow-hidden shrink-0 object-cover object-top  md:mt-0"
                alt=""
                src="/home-screen-mockup@2x.png"
              />
            </animated.div>
          </div>

          <animated.div ref={refBottom} style={bottomAnimation} className="self-stretch  flex flex-row items-center justify-center gap-2 w-full md:flex-wrap">
          <div  className="flex flex-col w-[40%] md:w-full h-96 rounded-md  [background:linear-gradient(180deg,_rgba(255,_255,_255,_0.03),_rgba(255,_255,_255,_0))] border-[2px] border-solid border-[#1A1A2C]  text-gray-400  overflow-hidden  items-start text-left  justify-start pt-10 md:pt-5  px-10 md:px-5 box-border gap-4 ">
            
            <img
                  className="absolute top-[0px] left-[0px] [transform:_rotate(-180deg)] z-0 opacity-50"
                  alt=""
                  src="/vector-bg.svg"
                />
             <img
                className="self-stretch h-40 md:object-contain z-10 relative w-full overflow-hidden shrink-0 object-cover object-top md:mt-0 mb-4"
                alt=""
                src="/beneficiaries.svg"
              />
              <div className="self-stretch rounded-[21.69px] w-full flex flex-col items-start justify-start gap-2 -mt-4">
               <div className="flex flex-col">
               <p className="m-0 self-stretch relative text-3xl text-white  font-normal font-inherit ">
               Save friends a
                </p>
                <p className="m-0 self-stretch relative text-3xl text-white  font-normal font-inherit ">
                beneficiaries
                </p>
               </div>
               <h3 className="m-0 self-stretch relative text-sm tracking-[-0.03em] md:text-sm   font-normal font-poppins text-gray-400  ">
               Effortlessly save friends and beneficiaries with Peniwallet, quickly manage your contacts for smooth and secure financial interactions.              </h3>
              </div>
            
            </div>
          <div className="flex flex-col w-[60%] md:w-full h-96 rounded-md  bg-[#090818] border-[2px] border-solid border-[#1A1A2C]  text-gray-400  overflow-hidden  items-start text-left justify-start pt-10 md:pt-5  px-10 md:px-5   box-border gap-4 ">
             
          <img
                  className="absolute top-[0px] left-[0px] [transform:_rotate(-180deg)] z-0 opacity-50"
                  alt=""
                  src="/vector-bg.svg"
                />
                 <img
                className="self-end h-48 md:object-contain relative w-full overflow-hidden shrink-0 object-contain -mr-20 mb-4" 
                alt=""
                src="/img-spray.svg"
              />
              <div className="self-stretch rounded-[21.69px] w-2/3 md:w-full flex flex-col items-start justify-start gap-2 -mt-4">
               <div className="flex flex-col">
               <p className="m-0 self-stretch relative text-3xl text-white  font-normal font-inherit ">
               Create and participate
                </p>
                <p className="m-0 self-stretch relative text-3xl text-white  font-normal font-inherit ">
              in spray events
                </p>
               </div>
               <h3 className="m-0 self-stretch relative text-sm tracking-[-0.03em] md:text-sm   font-normal font-poppins text-gray-400  ">
               Send crypto to your friends and family simultaenously with our spray party feature.
              </h3>
              </div>
             
             
            </div>
           
          </animated.div>
        </div>
       
    </div>
  );
};

export default FeaturesContent;
