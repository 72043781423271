import { FunctionComponent } from "react";
import React from "react";
export type FrameComponent11Type = {
  className?: string;
};

const FrameComponent11: FunctionComponent<FrameComponent11Type> = ({
  className = "",
}) => {
  return (
    <section
      className={`w-[1716px] flex flex-row items-start justify-center pt-0 px-5 pb-0.5 box-border max-w-full text-left text-xl text-neutral-03 font-poppins ${className}`}
    >
      <div className="h-[656.2px] w-[1268px] relative max-w-full">
        <b className="absolute top-[0px] left-[0px] text-5xl leading-[87.8px] capitalize inline-block w-[475px] sm:text-lgi sm:leading-[69px]">
          Governing Law and Dispute Resolution
        </b>
        <div className="absolute top-[84.2px] left-[0px] w-[1268px] flex flex-col items-start justify-start gap-[27px] max-w-full">
          <b className="h-3.5 relative leading-[86px] inline-block z-[1] sm:text-base sm:leading-[69px]">
            Choice of Law
          </b>
          <div className="self-stretch relative text-lg leading-[28px] font-medium text-aliceblue-100">
            <p className="m-0">
              This Privacy Policy is governed by the provisions of the General
              Data Protection Regulation (GDPR), which is the European Union's
              comprehensive data protection law.
            </p>
            <p className="m-0"> </p>
            <p className="m-0">
              In addition to GDPR, for users in Nigeria, Singapore, and South
              Korea, the applicable privacy laws are as follows:
            </p>
            <p className="m-0">&nbsp;</p>
            <ul className="m-0 font-inherit text-inherit pl-6">
              <li className="mb-0">
                Nigeria: The Nigerian Data Protection Regulation (NDPR)
              </li>
              <li className="mb-0">
                Singapore: The Personal Data Protection Act (PDPA)
              </li>
              <li className="mb-0">
                South Korea: The Personal Information Protection Act (PIPA)
              </li>
            </ul>
            <p className="m-0"> </p>
            <p className="m-0">
              Disputes arising under or in connection with this Privacy Policy
              will be resolved in accordance with the applicable laws and
              regulations of the respective jurisdictions, ensuring that our
              commitment to privacy aligns with the legal frameworks of these
              regions.
            </p>
            <p className="m-0"> </p>
          </div>
        </div>
        <div className="absolute top-[475.2px] left-[0px] w-[1268px] flex flex-col items-start justify-start gap-[27px] max-w-full">
          <b className="h-3.5 relative leading-[86px] inline-block max-w-full sm:text-base sm:leading-[69px]">
            Cooperation with Regulatory Authorities
          </b>
          <div className="self-stretch relative text-lg leading-[28px] font-medium text-aliceblue-100">
            In our commitment to legal compliance, Peniwallet pledges
            cooperation with regulatory authorities. We recognize the importance
            of working transparently with relevant bodies to ensure alignment
            with privacy and data protection regulations. This cooperative
            approach strengthens our commitment to maintaining the highest
            standards of legality and ethics in the decentralized financial
            landscape. By collaborating with regulatory authorities, Peniwallet
            aims to contribute to the ongoing development of a secure and
            compliant environment for users worldwide.
          </div>
        </div>
      </div>
    </section>
  );
};

export default FrameComponent11;
