import { FunctionComponent } from "react";
import { useSpring, animated } from 'react-spring';
import { useInView } from 'react-intersection-observer';
import React from "react";
export type SecretType = {
  className?: string;
};

const Secret: FunctionComponent<SecretType> = ({ className = "" }) => {
  const [refLeft, inViewLeft] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  const [refRight, inViewRight] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  
  const [refBottom, inViewBottom] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  const [refAboutLeft, inViewAboutLeft] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  const [refAboutRight, inViewAboutRight] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  const [refAboutBottom, inViewAboutBottom] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  
  const animation = useSpring({
    opacity: 1,
    transform: 'translateY(0)',
    from: { opacity: 0, transform: 'translateY(50px)' },
    config: { duration: 1000 },
  });
  const animationLeft = useSpring({
    opacity: inViewLeft ? 1 : 0,
    transform: inViewLeft ? 'translateX(0)' : 'translateX(-50px)', // Translate from left
    config: { duration: 700 },
  });
  
  const animationRight = useSpring({
    opacity: inViewRight ? 1 : 0,
    transform: inViewRight ? 'translateX(0)' : 'translateX(50px)', // Translate from right
    config: { duration: 700 },
  });
  
  const animationAbout = useSpring({
    opacity: 1,
    transform: 'translateY(0)',
    from: { opacity: 0, transform: 'translateY(50px)' },
    config: { duration: 700 },
  });
  const animationAboutLeft = useSpring({
    opacity: inViewAboutLeft ? 1 : 0,
    transform: inViewAboutLeft ? 'translateX(0)' : 'translateX(-50px)', // Translate from left
    config: { duration: 700 },
  });
  
  const animationAboutRight = useSpring({
    opacity: inViewAboutRight ? 1 : 0,
    transform: inViewAboutRight ? 'translateX(0)' : 'translateX(50px)', // Translate from right
    config: { duration: 700 },
  });
  const animationAboutBottom = useSpring({
    opacity: inViewAboutBottom ? 1 : 0,
    transform: inViewAboutBottom ? 'translateY(0)' : 'translateY(50px)',
    config: { duration: 700 }, // Adjust animation duration
  });
  const bottomAnimation = useSpring({
    opacity: inViewBottom ? 1 : 0,
    transform: inViewBottom ? 'translateY(0)' : 'translateY(50px)',
    config: { duration: 700 }, // Adjust animation duration
  });
  return (
    <section
    className={`self-stretch flex flex-row items-start justify-center py-40 md:py-10 px-32 md:px-6  bg-gray1-400  box-border w-full text-left text-29xl text-font-color-white font-polysans-trial md:box-border  sm:box-border ${className}`}
  >
    <div className="flex flex-row md:flex-col items-start justify-start w-full">
    <animated.div ref={refLeft} style={animationLeft} className="w-1/3 md:w-full flex flex-col items-start justify-start gap-[34px]  md:flex-1 sm:gap-[17px]">
    <h1 className="m-0 w-[388.4px] relative text-inherit leading-[84.5px] font-normal font-inherit inline-block shrink-0 min-w-[388.4px] max-w-full z-[1] md:text-37xl md:leading-[68px] md:min-w-full sm:text-23xl sm:leading-[51px] xl:flex-1">
        <p className="m-0">What’s our</p>
        <p className="m-0">Secret?</p>
      </h1>
      </animated.div>
      <animated.div ref={refBottom} style={bottomAnimation}  className="w-2/3 md:w-full flex flex-col items-start justify-start gap-[51px]   text-mini md:gap-[25px]">
      <div className="w-full flex flex-row md:flex-col items-start justify-start gap-[63px]  sm:gap-[31px]">
            <div className="w-1/2 md:w-full  flex flex-col items-start justify-start gap-4">
              <h1 className="m-0 w-[241.3px] relative text-inherit leading-[40px] font-normal font-inherit inline-block z-[1] md:text-7xl md:leading-[32px] sm:text-lgi sm:leading-[24px]">{`Clear Goals and Vision `}</h1>
              <div className="self-stretch relative text-base leading-[152%] font-plus-jakarta-sans text-darkgray-100 z-[1]">
                we have set ambitious yet realistic goals. These goals are the
                stepping stones that lead us to success, and they reflect our
                commitment to excellence in every aspect of our business.
              </div>
            </div>
            <div className="w-1/2 md:w-full flex flex-col items-start justify-start gap-4">
              <h1 className="m-0 self-stretch relative text-inherit leading-[40px] font-normal font-inherit z-[1] md:text-7xl md:leading-[32px] sm:text-lgi sm:leading-[24px]">
              Innovative Approach
              </h1>
              <div className="w-[263px] relative text-base leading-[150.5%] font-plus-jakarta-sans text-darkgray-100 inline-block z-[1]">
              Our commitment to innovation is reflected in our investment in research and development, strategic partnerships, and staying abreast of emerging technologies and trends.
              </div>
            </div>
          </div>
          <div className="w-full flex flex-row md:flex-col items-start justify-start gap-[63px]   sm:gap-[31px]">
          <div className="w-1/2 md:w-full  flex flex-col items-start justify-start gap-4">
            <h1 className="m-0 self-stretch relative text-inherit leading-[40px] font-normal font-inherit z-[1] md:text-7xl md:leading-[32px] sm:text-lgi sm:leading-[24px]">
            Customer-Centric Focus
              </h1>
              <div className="self-stretch relative text-base leading-[152%] font-plus-jakarta-sans text-darkgray-100 z-[1]">
              Our customers are at the core of our operations. All our activities as a business are geared towards making the lives of our customers easier.
              </div>
            </div>
            <div className="w-1/2 md:w-full  flex flex-col items-start justify-start gap-4">
              <h1 className="m-0 self-stretch relative text-inherit leading-[40px] font-normal font-inherit z-[1] md:text-7xl md:leading-[32px] sm:text-lgi sm:leading-[24px]">
              Increasing Value
              </h1>
              <div className="w-[263px] relative text-base leading-[150.5%] font-plus-jakarta-sans text-darkgray-100 inline-block z-[1]">
              Our customers are at the core of our operations. All our activities as a business are geared towards making the lives of our customers easier.              </div>
            </div>
          </div>
      </animated.div>
    
    </div>
  </section>
  );
};

export default Secret;
