import React, { FunctionComponent, useMemo, CSSProperties } from "react";

export type BlogPostCardType = {
  className?: string;
  image?: string;
  author?: string;
  heading?: string;
  supportingText?: string;

  /** Style props */
  propWidth?: string | number;
  propBorderRadius?: string | number;
};

const BlogPostCard: FunctionComponent<BlogPostCardType> = ({
  className = "",
  image,
  author,
  heading,
  supportingText,
  propWidth,
  propBorderRadius,
}) => {
  const blogPostCardStyle: CSSProperties = useMemo(() => {
    return {
      width: propWidth,
      borderRadius: propBorderRadius,
    };
  }, [propWidth, propBorderRadius]);

  return (
    <div
      className={`flex flex-col items-start justify-start gap-[32px] w-full text-left text-sm text-gainsboro-300 font-poppins sm:gap-[16px] ${className}`}
      style={blogPostCardStyle}
    >
      <img
        className="self-stretch h-60 relative rounded-6xl w-full overflow-hidden shrink-0 object-cover"
        loading="lazy"
        alt=""
        src={image}
      />
      <div className="self-stretch flex flex-col items-start justify-start w-full">
        <div className="self-stretch flex flex-col items-start justify-start gap-[12px] w-full">
          <div className="self-stretch relative leading-[20px] font-medium whitespace-nowrap">
            {author}
          </div>
          <div className="self-stretch flex flex-row items-start justify-start gap-[16px] max-w-full text-5xl text-font-color-white font-polysans-trial sm:flex-wrap">
            <h2 className="m-0 flex-1 relative text-inherit leading-[32px] font-normal font-inherit inline-block w-full sm:text-lgi sm:leading-[26px]">
              {heading}
            </h2>
            <div className="flex flex-col items-start justify-start pt-1 px-0 pb-0">
              <img
                className="w-6 h-6 relative overflow-hidden shrink-0"
                loading="lazy"
                alt=""
                src="/arrowupright.svg"
              />
            </div>
          </div>
          <div className="self-stretch relative text-base md:text-sm leading-[24px] text-gray1-100">
            {supportingText}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogPostCard;
