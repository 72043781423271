import { FunctionComponent } from "react";
import InputField from "./InputField";
import { useSpring, animated } from 'react-spring';
import { useInView } from 'react-intersection-observer';
import React from "react";
export type FrameComponent8Type = {
  className?: string;
};

const FrameComponent8: FunctionComponent<FrameComponent8Type> = ({
  className = "",
}) => {
  const [refLeft, inViewLeft] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  const [refRight, inViewRight] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  
  const [refBottom, inViewBottom] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  const [refAboutLeft, inViewAboutLeft] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  const [refAboutRight, inViewAboutRight] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  const [refAboutBottom, inViewAboutBottom] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  
  const animation = useSpring({
    opacity: 1,
    transform: 'translateY(0)',
    from: { opacity: 0, transform: 'translateY(50px)' },
    config: { duration: 1000 },
  });
  const animationLeft = useSpring({
    opacity: inViewLeft ? 1 : 0,
    transform: inViewLeft ? 'translateX(0)' : 'translateX(-50px)', // Translate from left
    config: { duration: 700 },
  });
  
  const animationRight = useSpring({
    opacity: inViewRight ? 1 : 0,
    transform: inViewRight ? 'translateX(0)' : 'translateX(50px)', // Translate from right
    config: { duration: 700 },
  });
  
  const animationAbout = useSpring({
    opacity: 1,
    transform: 'translateY(0)',
    from: { opacity: 0, transform: 'translateY(50px)' },
    config: { duration: 700 },
  });
  const animationAboutLeft = useSpring({
    opacity: inViewAboutLeft ? 1 : 0,
    transform: inViewAboutLeft ? 'translateX(0)' : 'translateX(-50px)', // Translate from left
    config: { duration: 700 },
  });
  
  const animationAboutRight = useSpring({
    opacity: inViewAboutRight ? 1 : 0,
    transform: inViewAboutRight ? 'translateX(0)' : 'translateX(50px)', // Translate from right
    config: { duration: 700 },
  });
  const animationAboutBottom = useSpring({
    opacity: inViewAboutBottom ? 1 : 0,
    transform: inViewAboutBottom ? 'translateY(0)' : 'translateY(50px)',
    config: { duration: 700 }, // Adjust animation duration
  });
  const bottomAnimation = useSpring({
    opacity: inViewBottom ? 1 : 0,
    transform: inViewBottom ? 'translateY(0)' : 'translateY(50px)',
    config: { duration: 700 }, // Adjust animation duration
  });

  return (
    <section
      className={`self-stretch flex flex-row items-start justify-center px-20 md:px-6  box-border w-full text-left text-45xl text-font-color-white font-polysans-trial  ${className}`}
    >
      <div className="flex flex-row items-start justify-start gap-20 w-full lg:flex-wrap ">
         <animated.div ref={refLeft} style={animationLeft} className="w-1/2 flex flex-col h-[600px] md:h-auto items-start justify-center  box-border    md:pt-[77px] md:box-border md:w-full">
          <div className="self-center flex flex-col items-start justify-center my-auto gap-[3px] w-full">
            <h1 className="m-0 self-center  text-37xl  capitalize font-normal font-inherit inline-block shrink-0 sm:text-19xl ">
              Our friendly team would love to hear from you.
            </h1>
            <div className="w-[386px] relative text-base tracking-[-0.02em] leading-[26px] font-medium font-poppins text-gainsboro-300 inline-block">
              We look forward to helping you on your journey.
            </div>
          </div>
        </animated.div>
        <animated.div ref={refRight} style={animationRight} className="w-1/2 rounded-13xl bg-gray1-200 overflow-hidden flex flex-col items-start justify-start p-[50px] box-border  text-sm text-gainsboro-200 md:gap-[24px] md:py-8 md:px-[25px] md:box-border md:min-w-full">
          <div className="self-stretch flex flex-col items-start justify-start gap-[32px] w-full md:gap-[16px]">
            <div className="self-stretch flex flex-col items-start justify-start gap-[24px] w-full">
              <InputField label="Name" contentPlaceholder="Your name" />
              <InputField label="Email" contentPlaceholder="you@company.com" />
              <div className="self-stretch flex flex-col items-start justify-start w-full">
                <div className="self-stretch flex flex-col items-start justify-start gap-[6px] w-full">
                  <div className="self-stretch flex flex-col items-start justify-start gap-[6px] w-full">
                    <div className="relative leading-[20px] inline-block min-w-[94px]">
                      Phone number
                    </div>
                    <div className="self-stretch shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] rounded-lg box-border overflow-hidden flex flex-row items-start justify-start w-full [row-gap:20px] text-base text-gray1-100 font-sansation border-[1px] border-solid border-dimgray-100 md:flex-wrap">
                      <div className="w-[70px] overflow-hidden shrink-0 flex flex-row items-center justify-between py-3 pr-3 pl-4 box-border gap-[0px] [row-gap:20px]">
                        <div className="relative leading-[24px] inline-block min-w-[22px]">
                          US
                        </div>
                        <img
                          className="h-5 w-5 relative overflow-hidden shrink-0"
                          alt=""
                          src="/chevrondown.svg"
                        />
                      </div>
                      <div className="flex-1 flex flex-row items-center justify-start py-3 pr-4 pl-0 box-border gap-[8px] min-w-[288px] w-full font-poppins">
                        <div className="flex-1 relative leading-[24px] inline-block w-full">
                          +1 (555) 000-0000
                        </div>
                        <img
                          className="h-4 w-4 relative hidden"
                          alt=""
                          src="/help-icon.svg"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="w-80 relative leading-[20px] font-text-sm-normal text-gray-500 hidden">
                    This is a hint text to help user.
                  </div>
                </div>
              </div>
              <div className="self-stretch flex flex-col items-start justify-start">
                <div className="self-stretch flex flex-col items-start justify-start gap-[6px]">
                  <div className="self-stretch flex flex-col items-start justify-start gap-[6px]">
                    <div className="w-[117px] relative leading-[20px] inline-block">
                      How can we help?
                    </div>
                    <textarea
                      className="bg-[transparent] h-32 w-auto [outline:none] self-stretch shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] rounded-lg box-border overflow-hidden shrink-0 flex flex-row items-center justify-start py-2.5 px-3.5 font-poppins text-base text-gray1-100 border-[1px] border-solid border-dimgray-100"
                      placeholder="Message"
                      rows={6}
                      cols={26}
                    />
                  </div>
                  <div className="w-80 relative leading-[20px] font-text-sm-normal text-gray-500 hidden">
                    This is a hint text to help user.
                  </div>
                </div>
              </div>
            </div>
            <button className="cursor-pointer [border:none] p-0 bg-[transparent] self-stretch flex flex-col items-start justify-start">
              <div className="self-stretch rounded-13xl bg-royalblue-200 flex flex-row items-center justify-center py-[15.5px] px-5">
                <div className="w-[91px] relative text-smi tracking-[-0.01em] leading-[24px] font-polysans-trial text-font-color-white text-left inline-block">
                  Send Message
                </div>
              </div>
            </button>
          </div>
        </animated.div>
      </div>

    </section>
  );
};

export default FrameComponent8;
