import { FunctionComponent } from "react";
import NavbarF from "./NavbarF";
import React from "react";
export type JoinPartyContentType = {
  className?: string;
};

const HeroFeatures: FunctionComponent<JoinPartyContentType> = ({
  className = "",
}) => {
  return (
    <section
      className={`self-stretch flex flex-col items-end justify-start max-w-full text-left text-63xl text-font-color-white font-polysans-trial ${className}`}
    >
      <NavbarF />
      <div className="h-auto flex-1 bg-black flex flex-col items-center justify-center pt-0 box-border gap-4 md:box-border">
       
        <div className="w-[80%]   h-full  flex flex-row items-start justify-center gap-1 mt-20  md:gap-4 md:flex-col self-center ">
            <div className="w-[50%] md:w-full flex flex-col items-start justify-start pt-10  pb-0 box-border   md:pt-4  animate__animated animate__fadeInLeft ">
              <div className="self-stretch flex flex-col items-start justify-start gap-[32px]  md:gap-[16px]">
                <h1 className="m-0 self-stretch  leading-none md:text-22xl text-[55px] font-normal md:text-center  ">
                 Share crypto to multiple wallets at-a-go
                </h1>
                <div className="w-full relative text-base md:text-sm tracking-[-0.02em]   font-poppins text-dimgray-200  md:text-center ">
                 Swap crypto directly from the Peniwallet mobile app and
                 browser extension.
                </div>
                <button className="cursor-pointer [border:none] py-[13px] px-[42px] bg-royalblue-200 w-[218px] rounded-13xl flex flex-row items-start justify-start box-border whitespace-nowrap hover:bg-dodgerblue">
                <div className="flex-1 relative text-smi tracking-[-0.01em] leading-[24px] font-polysans-trial text-font-color-white text-left">
                  Launch a Spray Party
                </div>
              </button>
              </div>
            </div>
            <img
              className="h-[600px] md:h-[350px] w-[50%] md:w-full md:object-cover overflow-hidden object-cover object-top  animate__animated animate__fadeInRight "
              loading="lazy"
              alt=""
              src="/spray-3@2x.png"
            />
          </div>
     
      </div>
 
    </section>
  );
};

export default HeroFeatures;
