import { FunctionComponent } from "react";
import { useSpring, animated } from 'react-spring';
import { useInView } from 'react-intersection-observer';
import React from "react";
export type RightContentType = {
  className?: string;
};

const RightContent: FunctionComponent<RightContentType> = ({
  className = "",
}) => {
  const [refLeft, inViewLeft] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  const [refRight, inViewRight] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  
  const [refBottom, inViewBottom] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  const [refAboutLeft, inViewAboutLeft] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  const [refAboutRight, inViewAboutRight] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  const [refAboutBottom, inViewAboutBottom] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  
  const animation = useSpring({
    opacity: 1,
    transform: 'translateY(0)',
    from: { opacity: 0, transform: 'translateY(50px)' },
    config: { duration: 1000 },
  });
  const animationLeft = useSpring({
    opacity: inViewLeft ? 1 : 0,
    transform: inViewLeft ? 'translateX(0)' : 'translateX(-50px)', // Translate from left
    config: { duration: 700 },
  });
  
  const animationRight = useSpring({
    opacity: inViewRight ? 1 : 0,
    transform: inViewRight ? 'translateX(0)' : 'translateX(50px)', // Translate from right
    config: { duration: 700 },
  });
  
  const animationAbout = useSpring({
    opacity: 1,
    transform: 'translateY(0)',
    from: { opacity: 0, transform: 'translateY(50px)' },
    config: { duration: 700 },
  });
  const animationAboutLeft = useSpring({
    opacity: inViewAboutLeft ? 1 : 0,
    transform: inViewAboutLeft ? 'translateX(0)' : 'translateX(-50px)', // Translate from left
    config: { duration: 700 },
  });
  
  const animationAboutRight = useSpring({
    opacity: inViewAboutRight ? 1 : 0,
    transform: inViewAboutRight ? 'translateX(0)' : 'translateX(50px)', // Translate from right
    config: { duration: 700 },
  });
  const animationAboutBottom = useSpring({
    opacity: inViewAboutBottom ? 1 : 0,
    transform: inViewAboutBottom ? 'translateY(0)' : 'translateY(50px)',
    config: { duration: 700 }, // Adjust animation duration
  });
  const bottomAnimation = useSpring({
    opacity: inViewBottom ? 1 : 0,
    transform: inViewBottom ? 'translateY(0)' : 'translateY(50px)',
    config: { duration: 700 }, // Adjust animation duration
  });
  return (
    <section
      className={`self-stretch flex flex-row items-start  bg-aliceblue-200 justify-center h-[1000px] md:h-auto  pt-0 pr-32 pl-32 md:pl-4 md:pr-4 box-border w-full text-left text-38xl-6 text-gray1-200 font-polysans-trial  lg:box-border  md:box-border ${className}`}
    >
      <div className="w-full flex flex-col items-start justify-start gap-[55.4px]  md:gap-[28px] my-auto md:my-10">
        <div className="flex flex-row items-start justify-start pt-[6.2px] px-0.5 pb-[6.4px] box-border relative shrink-0 ">
        <animated.div ref={refAboutLeft} style={animationAboutLeft} className="w-2/3 md:w-full    relative leading-[63px] flex items-center shrink-0 max-w-[110%] z-[1] sm:text-16xl sm:leading-[38px] md:text-27xl md:leading-[50px]">
            Launch a Spray party and share crypto to multiple wallets
          </animated.div>
        </div>
        <div className="self-stretch flex flex-row md:flex-col items-start justify-start py-0 pr-0 pl-0.5 box-border w-full text-5xl text-font-color-white font-aeonik gap-5">
        <animated.div ref={refLeft} style={animationLeft}  className="w-1/3 h-[504px] md:w-full self-stretch rounded-xl bg-gray1-800 flex flex-col items-end justify-start pt-0 px-0 pb-[14.9px] box-border gap-[19px]">
                <div className="self-stretch flex flex-col items-start justify-start pt-11 px-7 pb-7 gap-[10px] z-[1]">
                  <div className="self-stretch flex flex-col items-start justify-start">
                    <div className="w-[198px] flex flex-row items-start justify-start py-[0.5px] px-0 box-border">
                      <h3 className="m-0 flex-1 relative text-inherit leading-[28px] font-bold font-inherit sm:text-lgi sm:leading-[22px]">
                        Very low gas fees
                      </h3>
                    </div>
                  </div>
                  <div className="self-stretch flex flex-col items-start justify-start py-0 pr-5 pl-0 text-base text-silver-100">
                    <div className="w-full relative leading-[24px] inline-block max-w-[330px]">
                      <p className="m-0">
                        Get the best gas fee rates when you swap with
                        Peniwallet. Track our real-time rates
                      </p>
                      <p className="m-0">on the rates page.</p>
                    </div>
                  </div>
                </div>
               
              </animated.div>
              <animated.div ref={refBottom} style={bottomAnimation}  className="w-1/3 h-[504px] md:h-[404px] md:w-full flex flex-col self-stretch rounded-2xl bg-neutral-03 overflow-hidden  items-start justify-start">
                <div className="self-stretch h-[504px] relative [background:linear-gradient(180deg,_#f0f2f5_36%,_rgba(240,_242,_245,_0)_47%)] shrink-0 z-[1]" />
                <div className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-[472px] box-border max-w-full mt-[-504px] md:pb-[307px] md:box-border">
                  <div className="flex-1 flex flex-col items-start justify-start pt-11 px-7 pb-6 box-border gap-[10px] shrink-0 max-w-full z-[2]">
                    <div className="self-stretch flex flex-col items-start justify-start">
                      <div className="flex flex-row items-start justify-start py-[0.5px] px-0">
                        <h3 className="m-0 relative text-inherit leading-[28px] text-black font-bold font-inherit sm:text-lgi sm:leading-[22px]">
                          <p className="m-0">Faster</p>
                          <p className="m-0">Disbursement</p>
                        </h3>
                      </div>
                    </div>
                    <div className="self-stretch flex flex-col items-start justify-start py-0 pr-5 pl-0 text-base text-darkslategray-200">
                      <div className="w-full relative leading-[24px] inline-block max-w-[330px]">
                        Lightning-fast transaction at your convenience to all
                        your spray participants
                      </div>
                    </div>
                  </div>
                </div>
                <div className="self-stretch overflow-hidden flex flex-col items-center justify-center shrink-0 w-full mt-[-504px] md:mt-[-400px]">
                  <img
                    className="h-80 relative shrink-0"
                    loading="lazy"
                    alt=""
                    src="/clip-path-group.svg"
                  />
                </div>
              </animated.div>
              <animated.div ref={refRight} style={animationRight}  className="w-1/3 h-[504px] md:w-full self-stretch rounded-xl bg-gray1-800 flex flex-col items-end justify-start pt-0 px-0 pb-[14.9px] box-border gap-[19px]">
                <div className="self-stretch flex flex-col items-start justify-start pt-11 px-7 pb-7 gap-[10px] z-[1]">
                  <div className="self-stretch flex flex-col items-start justify-start">
                    <div className="w-[198px] flex flex-row items-start justify-start py-[0.5px] px-0 box-border">
                      <h3 className="m-0 flex-1 relative text-inherit leading-[28px] font-bold font-inherit sm:text-lgi sm:leading-[22px]">
                      Send crypto to friends and family all at once
                      </h3>
                    </div>
                  </div>
                  <div className="self-stretch flex flex-col items-start justify-start py-0 pr-5 pl-0 text-base text-silver-100">
                  <div className="text-base leading-[24px] text-silver-100 inline-block max-w-[330px] z-[1]">
                    <p className="m-0">
                      Swap funds from local to foreign and back
                    </p>
                    <p className="m-0">within seconds. No hidden fees.</p>
                  </div>
                  </div>
                  <div className="w-[295px] h-[178px] flex flex-row flex-wrap items-center justify-center relative gap-[8px_18px] z-[1]">
                <img
                  className="h-[85px] w-[85px] absolute !m-[0] top-[0px] left-[0px] object-contain"
                  loading="lazy"
                  alt=""
                  src="/select-an-emoji-avatar@2x.png"
                />
                <img
                  className="h-[85px] w-[85px] absolute !m-[0] top-[0px] left-[105px] object-contain"
                  loading="lazy"
                  alt=""
                  src="/select-an-emoji-avatar-1@2x.png"
                />
                <img
                  className="h-[85px] w-[85px] absolute !m-[0] top-[0px] left-[210px] object-contain"
                  loading="lazy"
                  alt=""
                  src="/select-an-emoji-avatar-2@2x.png"
                />
                <img
                  className="h-[85px] w-[85px] absolute !m-[0] top-[93px] left-[52.5px] object-contain"
                  loading="lazy"
                  alt=""
                  src="/select-an-emoji-avatar-3@2x.png"
                />
                <img
                  className="h-[85px] w-[85px] absolute !m-[0] top-[93px] left-[157.5px] object-contain"
                  loading="lazy"
                  alt=""
                  src="/select-an-emoji-avatar-4@2x.png"
                />
              </div>
                </div>
               
              </animated.div>
           
        </div>
      </div>
    </section>
  );
};

export default RightContent;
