import React from "react";
import {  useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";

export type NavbarFType = {
  className?: string;
};

const NavbarF: React.FC<NavbarFType> = ({ className = "" }) => {
  const [isMenuOpen, setMenuOpen] = React.useState(false);
  const navbarRef = React.useRef(null);
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [loggedIn, setLoggedIn] = React.useState(false);
  const [isssent, setIsSent] = React.useState(false);
  const handleMenuToggle = () => {
    setMenuOpen(!isMenuOpen);
  };

  const handleCloseMenu = () => {
    setMenuOpen(false);
  };
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false); 
  const navigate = useNavigate()
  const navigateToHome= () => {
    navigate('/');
  
  };
  const navigateToFeature= () => {
    // navigate('/features');
  
  };
  
  const navigateToDeveloper = () => {
    window.open('https://developers.peniwallet.com/', '_blank');
  };

  const navigateToResource= () => {
    // navigate('/resources');
    
     
   };
   const navigateTAbout= () => {
    // navigate('/about');
     
   };
  return (
    <nav className="flex justify-between items-center  w-full z-50     " ref={navbarRef}>
    <div className=" flex flex-row  justify-between items-center  w-full px-20 md:px-6 h-20 md:h-16">
    <img
      onClick={navigateToHome}
      loading="lazy"
      src="/peniwallet-logomark-1@2x.png"
      alt="Peniwallet Logo"
      className=" h-6  cursor-pointer"
    />


      <div className="hidden md:block ">
        {isMenuOpen ? (
          <div 
            onClick={handleCloseMenu}
            className="text-[#898989]"
          >
            <svg
             className=" flex w-6 h-6 items-center justify-center"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </div>
        ) : (
          <div 
            onClick={handleMenuToggle}
           
          >
              <svg width="20px" height="20px" viewBox="0 0 24 24" className=" flex w-6 h-6 items-center justify-center" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#000000">

              <g id="SVGRepo_bgCarrier" stroke-width="0"/>

              <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>

              <g id="SVGRepo_iconCarrier"> <path d="M4 6H20M4 12H14M4 18H9" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> </g>

              </svg>
          </div>
        )}
      </div>

      {isMenuOpen && (
        <div className="hidden md:block  items-center  justify-center text-sm  absolute top-14 right-0 left-0  bg-black z-50 w-full h-auto  p-4">
         
      
      <div className="flex flex-col gap-5 no-underline text-white" >

       
        <div onClick={navigateToFeature} className="cursor-pointer text-[#898989] hover:text-white-A700">Features</div>
        <div onClick={navigateToResource} className="cursor-pointer text-[#898989] hover:text-white-A700">Resources</div>
        <div onClick={navigateToDeveloper} className="cursor-pointer text-[#898989] hover:text-white-A700">Developer</div>
        <div onClick={navigateTAbout} className="cursor-pointer text-[#898989] hover:text-white-A700">About</div>
  

  

    <div className="mt-5">
      <button className="px-3.5 py-2 bg-blue-600 rounded-[32px] text-white">
        Download App
      </button>
    </div>

      </div>
        </div>
      )}

      <div className="md:hidden  flex flex-row  items-center justify-center">

      <div className="flex gap-5 justify-between  md:flex-wrap w-full">
        <div className="flex gap-5 justify-between self-start  md:flex-wrap md:w-full m-0 w-[450px]  flex-row items-start max-w-full shrink-0 text-left text-sm-8 text-neutral-03 font-polysans-trial">
        <div className="rounded-31xl overflow-hidden flex flex-row items-center justify-center py-3 px-5">
              <a  onClick={navigateToFeature} className="cursor-pointer [text-decoration:none] relative tracking-[-0.01em] leading-[20.65px] text-[inherit] inline-block min-w-[58px]">
                Features
              </a>
            </div>
            <div  onClick={navigateToResource} className="cursor-pointer flex-1 rounded-31xl overflow-hidden flex flex-row items-center justify-center py-3 px-5">
              <a className="[text-decoration:none] flex-1 relative tracking-[-0.01em] leading-[21px] text-[inherit]">
                Resources
              </a>
            </div>
            <div onClick={navigateToDeveloper} className="cursor-pointer flex-1 rounded-31xl overflow-hidden flex flex-row items-center justify-center py-3 px-5">
              <a className="[text-decoration:none] relative tracking-[-0.01em] leading-[21px] text-[inherit] inline-block min-w-[65px]">
                Developer
              </a>
            </div>
            <div  onClick={navigateTAbout} className="cursor-pointer w-[81px] rounded-31xl overflow-hidden shrink-0 flex flex-row items-center justify-center py-3 px-5 box-border">
              <a className="[text-decoration:none] flex-1 relative tracking-[-0.01em] leading-[21px] text-[inherit]">
                About
              </a>
            </div>
            
        </div>
        
        <button className="px-3.5 py-2 text-white bg-blue-600 rounded-[32px]">
        Download App
      </button>
      </div>
      </div>
 
    </div>
        </nav>
  );
};

export default NavbarF;
