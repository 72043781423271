import { FunctionComponent, useState } from "react";
import { useSpring, animated } from 'react-spring';
import { useInView } from 'react-intersection-observer';
import React from "react";
export type FAQType = {
  className?: string;
};
type FAQItem = {
  question: string;
  answer: string;
};

const faqs: FAQItem[] = [
  {
    question: "What is Peniwallet, and how does it differ from other cryptocurrency wallets?",
    answer: "Peniwallet is a non-custodial cryptocurrency wallet that allows users to store, manage, send, and receive cryptocurrencies. Unlike many other wallets, Peniwallet offers advanced features like gasless transactions, a chat-like transaction interface, and the ability to transfer tokens to over 100,000 addresses in one go."
  },
  {
    question: "How do gasless transactions work in Peniwallet?",
    answer: "Gasless transactions in Peniwallet allow you to pay for transaction fees in the token you are transferring or swapping, rather than needing separate gas tokens like BNB or ETH. This simplifies transactions, especially if you don't have the required gas tokens on hand."
  },
  {
    question: "Is Peniwallet available on both Android and iOS devices?",
    answer: "Yes, Peniwallet is available as a mobile application on both the Google Play Store for Android users and the Apple App Store for iOS users."
  },
  {
    question: "What is the Spray Feature, and how can I use it?",
    answer: "The Spray Feature is a unique tool in Peniwallet that allows users to create and participate in social giveaway parties. By creating a spray party and sharing an invite code, users can invite up to 100,000 participants to receive token transfers instantly during the party."
  },
  {
    question: "Can I swap tokens directly within Peniwallet?",
    answer: "Yes, Peniwallet has an integrated token swap feature that allows you to exchange BNB and other tokens directly within the wallet, eliminating the need to connect to external exchanges."
  },
  {
    question: "How does Peniwallet ensure the security of my private keys and seed phrases?",
    answer: "Peniwallet securely encrypts and stores your private keys and seed phrases on your device. Additionally, it tracks and provides a history of every instance the seed phrase is accessed, adding an extra layer of security."
  },
  {
    question: "What blockchains does Peniwallet currently support, and are there plans to add more?",
    answer: "Peniwallet currently focuses on the BNB Chain, but there are plans to expand support to other blockchains, including Ethereum, its Layer 2 solutions, Solana, TON, and more."
  },
];
const FAQ: FunctionComponent<FAQType> = ({ className = "" }) => {
  const [refLeft, inViewLeft] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  const [refRight, inViewRight] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  
  const [refBottom, inViewBottom] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  const [refAboutLeft, inViewAboutLeft] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  const [refAboutRight, inViewAboutRight] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  const [refAboutBottom, inViewAboutBottom] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  
  const animation = useSpring({
    opacity: 1,
    transform: 'translateY(0)',
    from: { opacity: 0, transform: 'translateY(50px)' },
    config: { duration: 1000 },
  });
  const animationLeft = useSpring({
    opacity: inViewLeft ? 1 : 0,
    transform: inViewLeft ? 'translateX(0)' : 'translateX(-50px)', // Translate from left
    config: { duration: 700 },
  });
  
  const animationRight = useSpring({
    opacity: inViewRight ? 1 : 0,
    transform: inViewRight ? 'translateX(0)' : 'translateX(50px)', // Translate from right
    config: { duration: 700 },
  });
  
  const animationAbout = useSpring({
    opacity: 1,
    transform: 'translateY(0)',
    from: { opacity: 0, transform: 'translateY(50px)' },
    config: { duration: 700 },
  });
  const animationAboutLeft = useSpring({
    opacity: inViewAboutLeft ? 1 : 0,
    transform: inViewAboutLeft ? 'translateX(0)' : 'translateX(-50px)', // Translate from left
    config: { duration: 700 },
  });
  
  const animationAboutRight = useSpring({
    opacity: inViewAboutRight ? 1 : 0,
    transform: inViewAboutRight ? 'translateX(0)' : 'translateX(50px)', // Translate from right
    config: { duration: 700 },
  });
  const animationAboutBottom = useSpring({
    opacity: inViewAboutBottom ? 1 : 0,
    transform: inViewAboutBottom ? 'translateY(0)' : 'translateY(50px)',
    config: { duration: 700 }, // Adjust animation duration
  });
  const bottomAnimation = useSpring({
    opacity: inViewBottom ? 1 : 0,
    transform: inViewBottom ? 'translateY(0)' : 'translateY(50px)',
    config: { duration: 700 }, // Adjust animation duration
  });

  const [expanded, setExpanded] = useState<number | null>(null);

  const toggleExpand = (index: number) => {
    setExpanded(expanded === index ? null : index);
  };
  return (
    <section
      className={`self-stretch flex flex-col items-center justify-center  box-border gap-20  text-center text-45xl text-font-color-white font-polysans-trial py-40 px-4  ${className}`}
    >
      <div className="w-2/3 md:w-full flex flex-col items-start justify-center gap-10 ">
      <animated.div ref={refLeft} style={animationLeft}className=" flex flex-col items-center justify-center w-full">



          <h1 className="m-0 self-stretch text-21xl   capitalize font-normal font-inherit inline-block shrink-0 z-[1] ">
            Frequently Asked Questions
          </h1>
          <div className="flex-1 relative tracking-[-0.02em] leading-[26px] font-medium inline-block text-sm z-[2]">
              We thought about some questions you may have and… here goes
           </div>
        </animated.div>
        <animated.div ref={refBottom} style={bottomAnimation} className="self-stretch flex flex-col items-start justify-start gap-4 w-full text-left text-lg font-text-sm-normal md:gap-[16px]">



        {faqs.map((faq, index) => (
        <div key={index} className="w-full gap-2 ">
          <div
            className="flex  justify-between items-center cursor-pointer rounded w-full"
            onClick={() => toggleExpand(index)}
          >
            <p className="font-semibold text-base md:text-sm">{faq.question}</p>
            <div >{expanded === index ? <img
                className="w-6 h-6 relative overflow-hidden shrink-0 z-[1]"
                loading="lazy"
                alt=""
                src="/pluscircle.svg"
              /> : <img
              className="w-6 h-6 relative overflow-hidden shrink-0"
              loading="lazy"
              alt=""
              src="/minuscircle.svg"
            />}</div>
          </div>
          {expanded === index && (
            <div className="text-base md:text-sm leading-[24px] text-gainsboro-300 font-normal ">
              <p >{faq.answer}</p>
            </div>
          )}
         <div className="self-stretch h-px relative bg-whitesmoke-300 z-[1]" />
        </div>
      ))}
      
    
       
        </animated.div>
      </div>
      
    </section>
  );
};

export default FAQ;
