import { FunctionComponent } from "react";
import NavbarF from "./NavbarF";
import React from "react";
export type FrameComponent10Type = {
  className?: string;
};

const FrameComponent10: FunctionComponent<FrameComponent10Type> = ({
  className = "",
}) => {
  return (
    <section
      className={`self-stretch flex flex-col items-end justify-start pt-0 px-0 pb-36 box-border relative gap-[104px] min-h-[507px] max-w-full text-center text-45xl text-neutral-03 font-polysans-trial sm:gap-[26px] sm:pb-[94px] sm:box-border md:gap-[52px] ${className}`}
    >
      <div className="w-full h-full absolute !m-[0] top-[-14px] right-[0px] bg-black" />
      <NavbarF />
      <div className="self-stretch flex flex-row items-start justify-center py-0 pr-5 pl-[21px] box-border max-w-full">
        <div className="w-[425px] flex flex-col items-start justify-start gap-[18px] max-w-full">
          <h1 className="m-0 self-stretch relative text-inherit leading-[86px] font-normal font-inherit z-[1] sm:text-19xl sm:leading-[52px] md:text-32xl md:leading-[69px]">
            Privacy Policy
          </h1>
          <div className="self-stretch flex flex-row items-start justify-start py-0 pr-[25px] pl-6 box-border max-w-full text-xl text-silver-200 font-poppins">
            <div className="flex-1 relative leading-[30px] font-medium inline-block max-w-full z-[1] sm:text-base sm:leading-[24px]">
              Last updated: June 05, 2024
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FrameComponent10;
