import { FunctionComponent } from "react";
import StepsContent from "./StepsContent";
import { useSpring, animated } from 'react-spring';
import { useInView } from 'react-intersection-observer';
import React from "react";
export type ValuesType = {
  className?: string;
};

const Values: FunctionComponent<ValuesType> = ({ className = "" }) => {
  const [refLeft, inViewLeft] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  const [refRight, inViewRight] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  
  const [refBottom, inViewBottom] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  const [refAboutLeft, inViewAboutLeft] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  const [refAboutRight, inViewAboutRight] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  const [refAboutBottom, inViewAboutBottom] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  
  const animation = useSpring({
    opacity: 1,
    transform: 'translateY(0)',
    from: { opacity: 0, transform: 'translateY(50px)' },
    config: { duration: 1000 },
  });
  const animationLeft = useSpring({
    opacity: inViewLeft ? 1 : 0,
    transform: inViewLeft ? 'translateX(0)' : 'translateX(-50px)', // Translate from left
    config: { duration: 700 },
  });
  
  const animationRight = useSpring({
    opacity: inViewRight ? 1 : 0,
    transform: inViewRight ? 'translateX(0)' : 'translateX(50px)', // Translate from right
    config: { duration: 700 },
  });
  
  const animationAbout = useSpring({
    opacity: 1,
    transform: 'translateY(0)',
    from: { opacity: 0, transform: 'translateY(50px)' },
    config: { duration: 700 },
  });
  const animationAboutLeft = useSpring({
    opacity: inViewAboutLeft ? 1 : 0,
    transform: inViewAboutLeft ? 'translateX(0)' : 'translateX(-50px)', // Translate from left
    config: { duration: 700 },
  });
  
  const animationAboutRight = useSpring({
    opacity: inViewAboutRight ? 1 : 0,
    transform: inViewAboutRight ? 'translateX(0)' : 'translateX(50px)', // Translate from right
    config: { duration: 700 },
  });
  const animationAboutBottom = useSpring({
    opacity: inViewAboutBottom ? 1 : 0,
    transform: inViewAboutBottom ? 'translateY(0)' : 'translateY(50px)',
    config: { duration: 700 }, // Adjust animation duration
  });
  const bottomAnimation = useSpring({
    opacity: inViewBottom ? 1 : 0,
    transform: inViewBottom ? 'translateY(0)' : 'translateY(50px)',
    config: { duration: 700 }, // Adjust animation duration
  });
  return (
    <section
    className={`self-stretch flex flex-row items-start justify-center pt-0 pb-20 px-32 md:px-6  box-border w-full text-left text-29xl text-font-color-white bg-royalblue-100  font-polysans-trial md:box-border  sm:box-border ${className}`}
  >
    <div className="flex flex-row md:flex-col items-start justify-start w-full my-40 md:my-10  ">
    <animated.div ref={refLeft} style={animationLeft}  className="w-1/2   md:w-full flex flex-col items-start justify-start gap-4 ">
        <button className="cursor-pointer py-2 px-[11px] bg-darkslategray-500 [backdrop-filter:blur(4px)] rounded-181xl flex flex-row items-center justify-center gap-[6px] whitespace-nowrap border-[1px] border-solid border-darkgray-200 hover:bg-dimgray-300 hover:box-border hover:border-[1px] hover:border-solid hover:border-gray1-1000">
          <img
            className="h-[18px] w-[18px] relative overflow-hidden shrink-0 min-h-[18px]"
            alt=""
            src="/filoader.svg"
          />
          <div className="relative text-mini tracking-[0.2em] font-polysans-trial text-font-color-white text-left">
            WHAT ARE WE ABOUT
          </div>
        </button>
        <div className="flex flex-col items-start justify-start gap-[36px] w-full ">
          <h1 className="m-0 relative text-16xl leading-[40px] font-normal font-inherit md:text-19xl md:leading-[49px] sm:text-10xl sm:leading-[37px]">{`We’re providing a seamless & secure cryptocurrency wallet experience that empowers users to effortlessly manage their digital assets`}</h1>
          <div className="relative  font-medium  w-full text-sm">
              The crypto app trusted by million of satisfied users, our
              financial services have made a real impact on people’s lives
          </div>
        </div>
      </animated.div>
      <animated.div ref={refRight} style={animationRight} className="w-1/2 md:w-full md:hidden">
        <img
          className="rounded-xl w-[460px]  object-cover z-[1]"
          loading="lazy"
          alt=""
          src="/rectangle-4707@2x.png"
        />
      </animated.div>
    
    </div>
  </section>
  );
};

export default Values;
