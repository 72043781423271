import { FunctionComponent } from "react";
import Main from "../components/Main";
import Hero from "../components/Hero";
import FAQ from "../components/FAQ";
import FrameComponent8 from "../components/FrameComponent8";
import Section5Footer2 from "../components/Section5Footer2";
import Footer from "../components/Footer";
import NavbarF from "../components/NavbarF";
import React from "react";

const PeniwalletSupport: FunctionComponent = () => {
  return (
    <div className="w-full relative bg-gray1-700 overflow-hidden flex flex-col items-end justify-start gap-[183px] leading-[normal] tracking-[normal] lg:gap-[91px] sm:gap-[46px]">
      <NavbarF />
      <Hero />
      <FAQ />
      <FrameComponent8 />

      <Footer />
    </div>
  );
};

export default PeniwalletSupport;
