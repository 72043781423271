import { useEffect } from "react";
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import PeniwalletHome from "./pages/PeniwalletHome";
import PeniwalletFeatures from "./pages/PeniwalletFeatures";
import PeniwalletSupport from "./pages/PeniwalletSupport";
import PeniwalletAbout from "./pages/PeniwalletAbout";
import PeniwalletPrivacyPolicy from "./pages/PeniwalletPrivacyPolicy";
import React from "react";

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  
  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
      case "/peniwallet-features":
        title = "";
        metaDescription = "";
        break;
      case "/peniwallet-support":
        title = "";
        metaDescription = "";
        break;
      case "/peniwallet-about":
        title = "";
        metaDescription = "";
        break;
      case "/peniwallet-privacy-policy":
        title = "";
        metaDescription = "";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag: HTMLMetaElement | null = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={<PeniwalletHome />} />
      <Route path="/features" element={<PeniwalletFeatures />} />
      <Route path="/resources" element={<PeniwalletSupport />} />
      <Route path="/about" element={<PeniwalletAbout />} />
      <Route
        path="/peniwallet-privacy-policy"
        element={<PeniwalletPrivacyPolicy />}
      />
    </Routes>
  );
}
export default App;
