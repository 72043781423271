import { FunctionComponent } from "react";
import React from "react";
export type FrameComponent1Type = {
  className?: string;
};

const FrameComponent1: FunctionComponent<FrameComponent1Type> = ({
  className = "",
}) => {
  return (
    <div
      className={`self-stretch flex flex-row items-start justify-between pt-0 px-20 pb-10 gap-[20px] text-center text-mini text-gainsboro-300 font-polysans-trial md:flex-wrap md:pl-10 md:pr-10 md:box-border ${className}`}
    >
      <div className="w-[257px] relative tracking-[-0.03em] leading-[82%] inline-block">
        © 2024 Peniwallet. All Right Reserved
      </div>
      <div className="w-[155px] flex flex-row items-center justify-between">
        <div className="h-[31px] w-[31px] rounded-[46.48px] bg-gray1-600 flex flex-row items-center justify-center p-1.5 box-border">
          <img
            className="h-[19px] w-[19px] relative"
            loading="lazy"
            alt=""
            src="/vuesaxboldinstagram.svg"
          />
        </div>
        <div className="h-[31px] w-[31px] rounded-[46.48px] bg-gray1-600 flex flex-row items-center justify-center p-1.5 box-border">
          <img
            className="h-[19px] w-[19px] relative overflow-hidden shrink-0"
            loading="lazy"
            alt=""
            src="/icon--outlined--brand-and-logo--facebook.svg"
          />
        </div>
        <div className="h-[31px] w-[31px] rounded-[46.48px] bg-gray1-600 flex flex-row items-center justify-center p-1.5 box-border">
          <img
            className="h-[19px] w-[19px] relative overflow-hidden shrink-0"
            loading="lazy"
            alt=""
            src="/icon--outlined--brand-and-logo--twitter.svg"
          />
        </div>
        <div className="h-[31px] w-[31px] rounded-[46.48px] bg-gray1-600 flex flex-row items-center justify-center p-1.5 box-border">
          <img
            className="h-[19px] w-[19px] relative overflow-hidden shrink-0"
            loading="lazy"
            alt=""
            src="/icon--outlined--brand-and-logo--linkedin.svg"
          />
        </div>
      </div>
    </div>
  );
};

export default FrameComponent1;
