import { FunctionComponent } from "react";
import Main from "../components/Main";
import Content from "../components/Content";
import Values from "../components/Values";
import CoreValues from "../components/CoreValues";
import Secret from "../components/Secret";
import FrameComponent9 from "../components/FrameComponent9";
import NavbarF from "../components/NavbarF";
import Footer from "../components/Footer";
import React from "react";

const PeniwalletAbout: FunctionComponent = () => {
  return (
    <div className="w-full relative bg-gray1-700 overflow-hidden flex flex-col items-start justify-start gap-[154px] leading-[normal] tracking-[normal] md:gap-[77px] sm:gap-[38px]">
       <NavbarF />
      <Content />
      <Values />
      <CoreValues />
      <Secret />
 
      <Footer />
    </div>
  );
};

export default PeniwalletAbout;
