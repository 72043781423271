import { FunctionComponent } from "react";
import StepsContent from "./StepsContent";
import DownloadInstructions from "./DownloadInstructions";
import { useSpring, animated } from 'react-spring';
import { useInView } from 'react-intersection-observer';
import React from "react";
export type FrameComponent4Type = {
  className?: string;
};

const FrameComponent4: FunctionComponent<FrameComponent4Type> = ({
  className = "",
}) => {
  const [refLeft, inViewLeft] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  const [refRight, inViewRight] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  
  const [refBottom, inViewBottom] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  const [refAboutLeft, inViewAboutLeft] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  const [refAboutRight, inViewAboutRight] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  const [refAboutBottom, inViewAboutBottom] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  
  const animation = useSpring({
    opacity: 1,
    transform: 'translateY(0)',
    from: { opacity: 0, transform: 'translateY(50px)' },
    config: { duration: 1000 },
  });
  const animationLeft = useSpring({
    opacity: inViewLeft ? 1 : 0,
    transform: inViewLeft ? 'translateX(0)' : 'translateX(-50px)', // Translate from left
    config: { duration: 700 },
  });
  
  const animationRight = useSpring({
    opacity: inViewRight ? 1 : 0,
    transform: inViewRight ? 'translateX(0)' : 'translateX(50px)', // Translate from right
    config: { duration: 700 },
  });
  
  const animationAbout = useSpring({
    opacity: 1,
    transform: 'translateY(0)',
    from: { opacity: 0, transform: 'translateY(50px)' },
    config: { duration: 700 },
  });
  const animationAboutLeft = useSpring({
    opacity: inViewAboutLeft ? 1 : 0,
    transform: inViewAboutLeft ? 'translateX(0)' : 'translateX(-50px)', // Translate from left
    config: { duration: 700 },
  });
  
  const animationAboutRight = useSpring({
    opacity: inViewAboutRight ? 1 : 0,
    transform: inViewAboutRight ? 'translateX(0)' : 'translateX(50px)', // Translate from right
    config: { duration: 700 },
  });
  const animationAboutBottom = useSpring({
    opacity: inViewAboutBottom ? 1 : 0,
    transform: inViewAboutBottom ? 'translateY(0)' : 'translateY(50px)',
    config: { duration: 700 }, // Adjust animation duration
  });
  const bottomAnimation = useSpring({
    opacity: inViewBottom ? 1 : 0,
    transform: inViewBottom ? 'translateY(0)' : 'translateY(50px)',
    config: { duration: 700 }, // Adjust animation duration
  });
  return (
    <section
      className={`self-stretch flex flex-row items-start  justify-center py-32 md:py-4 px-32 md:px-6  box-border w-full text-left text-29xl text-font-color-white font-polysans-trial md:box-border  sm:box-border ${className}`}
    >
      <div className="flex flex-row items-start justify-start w-full md:gap-[36px]  xl:flex-wrap xl:gap-[73px]">
        <div className="w-1/2 flex flex-col items-start justify-start pt-[60px] px-0 pb-0 box-border  md:pt-[39px] md:box-border md:min-w-full xl:flex-1">
          <div className="self-stretch flex flex-col items-start justify-start gap-[20px] max-w-full">
            <button className="cursor-pointer py-2 px-[11px] bg-darkslategray-500 [backdrop-filter:blur(4px)] rounded-181xl flex flex-row items-start justify-start gap-[8px] whitespace-nowrap border-[1px] border-solid border-darkgray-200 hover:bg-dimgray-300 hover:box-border hover:border-[1px] hover:border-solid hover:border-gray1-1000">
              <img
                className="h-[18px] w-[18px] relative overflow-hidden shrink-0 min-h-[18px]"
                alt=""
                src="/filoader.svg"
              />
              <div className="relative text-mini font-polysans-trial text-font-color-white text-left inline-block min-w-[92px]">
                How it works
              </div>
            </button>
            <div className="self-stretch flex flex-col items-start justify-start gap-[20px] max-w-full">
            <animated.div ref={refLeft} style={animationLeft} className="m-0 w-[455px] relative text-inherit tracking-[-0.01em] leading-[60px] font-normal font-inherit inline-block max-w-full md:text-19xl md:leading-[48px] sm:text-10xl sm:leading-[36px]">
                Everything you Need in one Wallet
              </animated.div>
              <animated.div ref={refAboutBottom} style={animationAboutBottom} className="self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-2.5 box-border max-w-full text-base text-gray1-1100 font-poppins">
                <div className="flex-1 relative leading-[28px] inline-block text-base md:text-sm">
                  Peniwallet is not just another crypto wallet; it's your
                  gateway to a secure, efficient, and user-friendly crypto
                  experience. Here’s 3 easy steps to use our service
                </div>
              </animated.div>
              <animated.div ref={refRight} style={animationRight} className="w-[374px] flex flex-col items-start justify-start gap-[29px] max-w-full text-lg">
                <StepsContent
                  iconOutlinedActionDownloa="/icon--outlined--action--downloadupload--download-cloud.svg"
                  downloadAppSignUp={`Download App & Sign Up`}
                  firstDownloadOurAppOnAppS="First download our app on App store or google store and register. "
                />
                <StepsContent
                  iconOutlinedActionDownloa="/vuesaxlinearscan.svg"
                  downloadAppSignUp="Create and backup your wallet"
                  firstDownloadOurAppOnAppS="Provide any means of identification to verify your details to secure your account."
                  propWidth="342px"
                  
                />
                <DownloadInstructions
                  vuesaxlinearlikeShapes="/vuesaxlinearlikeshapes.svg"
                  startEnjoyingOurServices="Start Enjoying our services"
                  enjoyOurUnlimitedServices="Enjoy our unlimited services like buy and sell cryptocurrency, receive, swap, spray and more."
                />
              </animated.div>
            </div>
          </div>
        </div>
        <animated.div ref={refRight} style={animationRight} className="h-[702px] md:h-[500px]   w-1/2 rounded-xl overflow-hidden sflex flex-row  bg-royalblue-200 items-center justify-start  md:w-full  xl:flex-1">
        <img
                className="h-[702px] w-full xl:pt-20     object-cover object-top z-[2]"
                loading="lazy"
                alt=""
                src="/home-screen-mockup@2x.png"
              />
        </animated.div>
      </div>
    </section>
  );
};

export default FrameComponent4;
