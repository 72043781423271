import { FunctionComponent } from "react";
import StepsContent from "./StepsContent";
import { useSpring, animated } from 'react-spring';
import { useInView } from 'react-intersection-observer';
import React from "react";
export type CoreValuesType = {
  className?: string;
};

const CoreValues: FunctionComponent<CoreValuesType> = ({ className = "" }) => {
  const [refLeft, inViewLeft] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  const [refRight, inViewRight] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  
  const [refBottom, inViewBottom] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  const [refAboutLeft, inViewAboutLeft] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  const [refAboutRight, inViewAboutRight] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  const [refAboutBottom, inViewAboutBottom] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  
  const animation = useSpring({
    opacity: 1,
    transform: 'translateY(0)',
    from: { opacity: 0, transform: 'translateY(50px)' },
    config: { duration: 1000 },
  });
  const animationLeft = useSpring({
    opacity: inViewLeft ? 1 : 0,
    transform: inViewLeft ? 'translateX(0)' : 'translateX(-50px)', // Translate from left
    config: { duration: 700 },
  });
  
  const animationRight = useSpring({
    opacity: inViewRight ? 1 : 0,
    transform: inViewRight ? 'translateX(0)' : 'translateX(50px)', // Translate from right
    config: { duration: 700 },
  });
  
  const animationAbout = useSpring({
    opacity: 1,
    transform: 'translateY(0)',
    from: { opacity: 0, transform: 'translateY(50px)' },
    config: { duration: 700 },
  });
  const animationAboutLeft = useSpring({
    opacity: inViewAboutLeft ? 1 : 0,
    transform: inViewAboutLeft ? 'translateX(0)' : 'translateX(-50px)', // Translate from left
    config: { duration: 700 },
  });
  
  const animationAboutRight = useSpring({
    opacity: inViewAboutRight ? 1 : 0,
    transform: inViewAboutRight ? 'translateX(0)' : 'translateX(50px)', // Translate from right
    config: { duration: 700 },
  });
  const animationAboutBottom = useSpring({
    opacity: inViewAboutBottom ? 1 : 0,
    transform: inViewAboutBottom ? 'translateY(0)' : 'translateY(50px)',
    config: { duration: 700 }, // Adjust animation duration
  });
  const bottomAnimation = useSpring({
    opacity: inViewBottom ? 1 : 0,
    transform: inViewBottom ? 'translateY(0)' : 'translateY(50px)',
    config: { duration: 700 }, // Adjust animation duration
  });
  return (
    <section
    className={`self-stretch flex flex-row items-start justify-center pt-0 pb-20 px-32 md:px-6  box-border w-full text-left text-29xl text-font-color-white font-polysans-trial md:box-border  sm:box-border ${className}`}
  >
    <div className="flex flex-row md:flex-col gap-10 items-start justify-start w-full">
    <animated.div ref={refLeft} style={animationLeft} className="w-1/2 md:w-full flex flex-col items-start justify-start gap-4">
        <div className="flex flex-row items-start justify-start pt-0 px-0 pb-[11px]">
          <button className="cursor-pointer py-2 px-[11px] bg-darkslategray-500 [backdrop-filter:blur(4px)] rounded-181xl flex flex-row items-start justify-start gap-[6px] whitespace-nowrap border-[1px] border-solid border-darkgray-200 hover:bg-dimgray-300 hover:box-border hover:border-[1px] hover:border-solid hover:border-gray1-1000">
            <img
              className="h-[18px] w-[18px] relative overflow-hidden shrink-0 min-h-[18px]"
              alt=""
              src="/filoader.svg"
            />
            <div className="relative text-mini tracking-[0.2em] font-polysans-trial text-font-color-white text-left">
              OUR CORE VALUES
            </div>
          </button>
        </div>
        <div className="w-[245px] flex flex-col items-start justify-start gap-[24px]">
          <div className="w-[206px] flex flex-row items-start justify-start gap-[29px]">
            <div className="h-11 w-11 rounded-[46.48px] bg-font-color-white flex flex-row items-center justify-center py-3.5 px-2.5 box-border">
              <img
                className="h-6 w-6 relative overflow-hidden shrink-0"
                loading="lazy"
                alt=""
                src="/icon--outlined--action--downloadupload--download-cloud.svg"
              />
            </div>
            <div className="flex-1 flex flex-col items-start justify-start pt-2 px-0 pb-0">
              <div className="self-stretch relative text-base">
                Grow not blame
              </div>
            </div>
          </div>
          <div className="self-stretch flex flex-row items-start justify-start gap-[26px]">
            <div className="h-11 w-11 rounded-[46.48px] bg-font-color-white flex flex-row items-center justify-center py-3.5 px-2.5 box-border">
              <img
                className="h-6 w-6 relative"
                loading="lazy"
                alt=""
                src="/vuesaxlinearlikeshapes.svg"
              />
            </div>
            <div className="flex-1 flex flex-col items-start justify-start pt-2 px-0 pb-0">
              <div className="self-stretch relative text-base ">
                We embrace diversity
              </div>
            </div>
          </div>
          <div className="w-[199px] flex flex-row items-start justify-start gap-[29px]">
            <div className="h-11 w-11 rounded-[46.48px] bg-font-color-white flex flex-row items-center justify-center py-3.5 px-2.5 box-border">
              <img
                className="h-6 w-6 relative"
                loading="lazy"
                alt=""
                src="/vuesaxlinearscan.svg"
              />
            </div>
            <div className="flex-1 flex flex-col items-start justify-start pt-2 px-0 pb-0">
              <div className="self-stretch relative text-base">
                We are curious
              </div>
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-row items-start justify-start gap-[29px]">
          <div className="h-11 w-11 rounded-[46.48px] bg-font-color-white flex flex-row items-center justify-center py-3.5 px-2.5 box-border">
            <img
              className="h-6 w-6 relative"
              loading="lazy"
              alt=""
              src="/vuesaxlinearscan.svg"
            />
          </div>
          <div className="flex-1 flex flex-col items-start justify-start pt-2 px-0 pb-0">
            <div className="self-stretch relative text-base">
              We encourage Empathy
            </div>
          </div>
        </div>
      </animated.div>
      <animated.div ref={refRight} style={animationRight} className="w-1/2 md:w-full flex flex-col items-start justify-start gap-4">
        <div className="[backdrop-filter:blur(4px)] rounded-181xl bg-darkslategray-500 flex flex-row items-start justify-start py-2 px-[11px] gap-[5px] whitespace-nowrap border-[1px] border-solid border-darkgray-200">
          <img
            className="h-[18px] w-[18px] relative overflow-hidden shrink-0 min-h-[18px]"
            alt=""
            src="/filoader.svg"
          />
          <div className="relative tracking-[0.2em] text-sm">
            WHY CHOOSE PENIWALLET
          </div>
        </div>
        <div className="self-stretch flex flex-row items-start justify-start py-0 pr-0 pl-[5px] box-border max-w-full text-lg font-poppins">
          <div className="flex-1 relative leading-[150.98%] inline-block max-w-full">
            <p className="m-0">
              About Peniwallet: Making Crypto Simple for Everyone
            </p>
            <p className="m-0">
              Peniwallet was founded with a single mission: to break down the
              barriers to entry and make the world of cryptocurrency
              accessible to everyone. We believe that crypto has the potential
              to revolutionize finance, but its complexity can often be
              intimidating for newcomers. That's where Peniwallet comes in.
            </p>
            <p className="m-0">&nbsp;</p>
            <p className="m-0">
              Buying crypto is a breeze. Purchase your chosen cryptocurrencies
              directly using your debit/credit card or bank transfer. We offer
              a variety of popular options to suit your needs.
            </p>
            <p className="m-0">
              Need to convert your crypto holdings? Our seamless swapping
              feature allows you to effortlessly exchange between different
              cryptocurrencies within your Peniwallet. No need for external
              exchanges or complicated transfers.
            </p>
            <p className="m-0">&nbsp;</p>
            <p className="m-0">
              Peniwallet also makes sending crypto to others a breeze. Our
              innovative Spray Party feature lets you send crypto to multiple
              wallets at once. This is perfect for splitting bills with
              friends, sending gifts to family, or rewarding your team.
            </p>
            <p className="m-0">
              Security is our top priority. We prioritize the safety of your
              funds with industry-leading security measures. Sleep soundly
              knowing your crypto is protected.
            </p>
            <p className="m-0">&nbsp;</p>
            <p className="m-0">
              Join the Peniwallet family today and start your crypto journey
              with confidence!
            </p>
          </div>
        </div>
      </animated.div>
    
    </div>
  </section>
  );
};

export default CoreValues;
