import { FunctionComponent } from "react";
import { useSpring, animated } from 'react-spring';
import { useInView } from 'react-intersection-observer';
import React from "react";
export type ServiceDescriptionType = {
  className?: string;
};

const ServiceDescription: FunctionComponent<ServiceDescriptionType> = ({
  className = "",
}) => {
  const [refLeft, inViewLeft] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  const [refRight, inViewRight] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  
  const [refBottom, inViewBottom] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  const [refAboutLeft, inViewAboutLeft] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  const [refAboutRight, inViewAboutRight] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  const [refAboutBottom, inViewAboutBottom] = useInView({
    triggerOnce: true, // Only trigger once
    threshold: 0.5, // Trigger when 50% of the element is visible
  });
  
  
  const animation = useSpring({
    opacity: 1,
    transform: 'translateY(0)',
    from: { opacity: 0, transform: 'translateY(50px)' },
    config: { duration: 1000 },
  });
  const animationLeft = useSpring({
    opacity: inViewLeft ? 1 : 0,
    transform: inViewLeft ? 'translateX(0)' : 'translateX(-50px)', // Translate from left
    config: { duration: 700 },
  });
  
  const animationRight = useSpring({
    opacity: inViewRight ? 1 : 0,
    transform: inViewRight ? 'translateX(0)' : 'translateX(50px)', // Translate from right
    config: { duration: 700 },
  });
  
  const animationAbout = useSpring({
    opacity: 1,
    transform: 'translateY(0)',
    from: { opacity: 0, transform: 'translateY(50px)' },
    config: { duration: 700 },
  });
  const animationAboutLeft = useSpring({
    opacity: inViewAboutLeft ? 1 : 0,
    transform: inViewAboutLeft ? 'translateX(0)' : 'translateX(-50px)', // Translate from left
    config: { duration: 700 },
  });
  
  const animationAboutRight = useSpring({
    opacity: inViewAboutRight ? 1 : 0,
    transform: inViewAboutRight ? 'translateX(0)' : 'translateX(50px)', // Translate from right
    config: { duration: 700 },
  });
  const animationAboutBottom = useSpring({
    opacity: inViewAboutBottom ? 1 : 0,
    transform: inViewAboutBottom ? 'translateY(0)' : 'translateY(50px)',
    config: { duration: 700 }, // Adjust animation duration
  });
  const bottomAnimation = useSpring({
    opacity: inViewBottom ? 1 : 0,
    transform: inViewBottom ? 'translateY(0)' : 'translateY(50px)',
    config: { duration: 700 }, // Adjust animation duration
  });
  return (
    <section
      className={`self-stretch flex flex-row items-start bg-[#070614] justify-center py-0 px-32 md:px-6 box-border w-full text-center text-45xl text-gainsboro-300 font-polysans-trial ${className}`}
    >
      <div className="w-full flex flex-col items-start justify-start gap-[78px]  md:gap-[39px] sm:gap-[19px]">
        <div className="self-stretch flex flex-row items-start justify-center py-0 px-5 box-border max-w-full">
          
        <animated.div ref={refBottom} style={bottomAnimation}   className="w-[80%] md:w-full flex flex-col items-start justify-start gap-[28px] max-w-full">
          
            <h1 className="m-0 self-stretch relative text-27xl font-normal font-inherit text-center sm:text-19xl sm:leading-[45px] md:text-32xl md:leading-[60px]">           
              <p className="m-0">Simple. Seamless</p>
              <p className="m-0">Transacting Experience</p>
            </h1>
            <div className="self-stretch flex flex-row items-start justify-start py-0 px-[57px] box-border max-w-full text-lg md:text-sm text-lightslategray font-poppins md:pl-7 md:pr-7 md:box-border">
            <p className="m-0">
                  Enjoy a smooth mobile app and desktop experience with
                  easy-to-use, powerful tools to support your entire Web3
                  journey.
                </p>
            </div>
          </animated.div>
        </div>
        <div className="self-stretch flex flex-col items-start justify-start gap-[24px] w-full text-left text-19xl text-font-color-dark-800">
          <div className="self-stretch  flex flex-row items-center justify-center gap-[24px] w-full md:flex-wrap">
          <animated.div ref={refLeft} style={animationLeft} className="flex-1 h-[750px] md:h-[500px] rounded-xl bg-[#090818]  text-gray-400 border-[2px] border-solid border-[#1A1A2C] overflow-hidden flex flex-col items-center justify-start pt-[45px] px-[45px] pb-[508px] box-border gap-[20px]l te md:pl-[22px] md:pr-[22px] md:min-w-full md:flex-1 md:pt-[29px]  md:box-border sm:pt-5  sm:box-border">
            <div className="self-stretch rounded-[21.69px] flex flex-col items-start justify-start gap-[50px] md:gap-[25px]">
                <div className="w-14 h-14 rounded-[46.48px] bg-font-color-white flex flex-row items-center justify-center py-[20.5px] px-[23px] box-border">
                  <img
                    className="h-8 w-8 relative"
                    loading="lazy"
                    alt=""
                    src="/vuesaxlinearmobile.svg"
                  />
                </div>
                <h1 className="m-0 self-stretch relative text-white text-inherit tracking-[-0.01em] leading-[38px] font-normal font-inherit md:text-11xl md:leading-[30px] sm:text-4xl sm:leading-[22px]">
                  Sleek and Intuitive Design
                </h1>
              </div>
              <h3 className="m-0 mt-2  relative text-base  tracking-[-0.03em] leading-[33.68px] md:text-sm font-normal font-poppins text-dimgray-200 inline-block max-w-full  sm:leading-[27px]">
              Peniwallet features a sleek, intuitive design for effortless navigation and efficient financial management. Enjoy a modern, user-friendly experience that simplifies your financial tasks..
              </h3>
              <img
                className="self-stretch h-[1000px] md:h-[600px] md:object-cover  relative max-w-full overflow-hidden shrink-0 object-contain -mt-32 md:mt-0"
                alt=""
                src="/swap@2x.png"
              />
            </animated.div>
            <animated.div ref={refRight} style={animationRight} className="flex-1 h-[750px] md:h-[500px] rounded-xl  bg-[#090818] border-[2px] border-solid border-[#1A1A2C]  text-gray-400  overflow-hidden flex flex-col items-center justify-start pt-[45px] px-[45px] pb-[508px] box-border gap-[20px] md:pl-[22px] md:pr-[22px] md:min-w-full md:flex-1 md:pt-[29px]  md:box-border sm:pt-5  sm:box-border">
              <div className="self-stretch rounded-[21.69px] flex flex-col items-start justify-start gap-[50px] md:gap-[25px]">
              <div className="w-14 h-14 rounded-[46.48px] bg-gainsboro-300 flex flex-row items-center justify-center p-[17px] box-border">
                  <img
                    className="h-8 w-8"
                    loading="lazy"
                    alt=""
                    src="/vuesaxlinearlock.svg"
                  />
                </div>
                <h1 className="m-0 self-stretch text-white text-inherit tracking-[-0.01em] leading-[38px] font-normal font-inherit md:text-11xl md:leading-[30px] sm:text-4xl sm:leading-[22px]">
                  Top-notch Security
                </h1>
              </div>
              <h3 className="m-0 self-stretch  text-base text-dimgray-200 tracking-[-0.03em] md:text-sm  leading-[33.68px] font-normal font-poppins   sm:leading-[27px]">
                Track your sending patterns,analyze income or expenses easily,
                and recieve personalized recommendations to optimize your
                financial habits.
              </h3>
              <img
                className="self-stretch h-[1000px] md:h-[600px] md:object-contain relative max-w-full overflow-hidden shrink-0 object-contain -mt-24 md:mt-0"
                alt=""
                src="/home-screen-mockup@2x.png"
              />
            </animated.div>
          </div>
          <animated.div ref={refAboutBottom} style={animationAboutBottom}  className="self-stretch md:h-[500px] rounded-xl bg-[#090818] border-[2px] border-solid border-[#1A1A2C] overflow-hidden flex flex-row flex-wrap items-end justify-center pt-[66.1px] px-[45px] pb-[21.1px] box-border gap-[20px] w-full text-whitesmoke-100 md:pt-[43px] md:pb-5 md:box-border xl:pl-[22px] xl:pr-[22px] xl:box-border">
            <div className="w-1/3 flex flex-col items-start justify-start gap-[35.8px]  md:gap-[18px] md:w-full">
              <div className="self-stretch rounded-[21.69px] flex flex-col items-start justify-start py-0 pr-5 pl-0 box-border gap-[50px] max-w-full md:gap-[25px]">
              <div className="w-14 h-14 rounded-[46.48px] bg-font-color-white flex flex-row items-center justify-center p-3.5 box-border">
                  <div className="flex flex-row items-center justify-center">
                    <img
                      className="h-8 w-8 "
                      loading="lazy"
                      alt=""
                      src="/vuesaxlinearmoneysend.svg"
                    />
                  </div>
                </div>
                <h1 className="m-0  relative text-inherit tracking-[-0.01em] leading-[45px] font-normal font-inherit inline-block max-w-full md:text-11xl md:leading-[36px] sm:text-4xl sm:leading-[27px]">
                  Deposit crypto easily from exchanges
                </h1>
              </div>
              <h3 className="m-0  relative text-base md:text-sm tracking-[-0.03em]  font-normal font-poppins text-dimgray-200 inline-block">
                Take control of your crypto. Avoid complicated steps and deposit
                directly to your wallet from exchanges like Binance and
                Coinbase.
              </h3>
            </div>
            <div className="w-1/2  flex flex-col items-start justify-end pt-0 md:-mt-16 px-0 pb-[27.7px] box-border md:w-full">
              <img
                className="self-stretch h-[351.4px] relative max-w-full overflow-hidden shrink-0"
                loading="lazy"
                alt=""
                src="/group-822.svg"
              />
            </div>
          </animated.div>
       
        </div>
      </div>
    </section>
  );
};

export default ServiceDescription;
